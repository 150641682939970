import { lazy, Suspense } from "react";

import MediumComp from '../comp/MediumComp';

const AboutUsPageMetaComp = (lazy(() => (import('../comp/AboutUsPageMetaComp'))));
const AboutUsPageHeaderComp = (lazy(() => (import('../comp/AboutUsPageHeaderComp'))));
const AboutUsPageComp = (lazy(() => (import('../comp/AboutUsPageComp'))));
const AboutUsPageComp2 = (lazy(() => (import('../comp/AboutUsPageComp2'))));
const AboutUsPagePartnersComp = (lazy(() => (import('../comp/AboutUsPagePartnersComp'))));

function AboutUsPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Suspense fallback={<div></div>}><AboutUsPageMetaComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><AboutUsPageHeaderComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><AboutUsPageComp mode={mode} /></Suspense>
      <div className="dark:bg-pgray-800 bg-pgray-50 flex">
        <div className="w-full pt-8 pb-8 sm:pt-16 sm:pb-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-4 sm:px-6 lg:px-6 border-t dark:border-pgray-600 border-pgray-200"></div>
        </div>
      </div>
      <Suspense fallback={<div></div>}><AboutUsPageComp2 mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><AboutUsPagePartnersComp mode={mode} /></Suspense>

      <MediumComp mode={mode} />
    </>
  );
}

export default AboutUsPage;