import { lazy, Suspense } from "react";
import { useParams, Navigate } from "react-router-dom";
import gql from "graphql-tag";
import Query from "../utils/Query";
import MediumComp from './MediumComp';

const UseCasesPageMetaComp = (lazy(() => (import('../comp/UseCasesPageMetaComp'))));
const CaseStudyPageHeader1Comp = (lazy(() => (import('../comp/CaseStudyPageHeader1Comp'))));
const CaseStudyPageDynamicZone1Comp = (lazy(() => (import('../comp/CaseStudyPageDynamicZone1Comp'))));
const CaseStudyPageDynamicZone2Comp = (lazy(() => (import('../comp/CaseStudyPageDynamicZone2Comp'))));
const CaseStudyPageDynamicZone3Comp = (lazy(() => (import('../comp/CaseStudyPageDynamicZone3Comp'))));
const CaseStudyPageDynamicZone4Comp = (lazy(() => (import('../comp/CaseStudyPageDynamicZone4Comp'))));
const CaseStudyPageContactUsComp = (lazy(() => (import('../comp/CaseStudyPageContactUsComp'))));

function CaseStudiesPage(props) {
  const mode = props.mode ? props.mode : "light";

  let { slug } = useParams();

  const CASESTUDYPAGES_QUERY = gql`
    query CaseStudyPages($slug: String!) {
      caseStudyPages(filters: { Slug: { eq: $slug } }) {
        data {
          attributes {
            Slug
            ShowFormBottom
            Meta {
              Title
              Desc
              Img {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            HeaderDynamicZone {
              __typename
              ... on ComponentBlockDzHeader01 {
                Images {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                PreTitle
                Title
                SubTitle
              } 
            }
            DynamicZone {
              __typename
              ... on ComponentBlockDynamicZone2Block {
                Text
                Image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                ShowBackground
                BlockWidth
              }
              ... on ComponentBlockDynamicZone3Block {
                Caption
                Images {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return (
    <>
      <Query query={CASESTUDYPAGES_QUERY} id={null} slug={slug}>
        {({ data: { caseStudyPages } }) => {
          return (
            <>
              {caseStudyPages.data.length == 0 && 
                <Navigate replace to="/404" />
              }
              
              <Suspense fallback={<div></div>}><UseCasesPageMetaComp mode={mode} data={caseStudyPages.data[0].attributes.Meta}/></Suspense>
              {caseStudyPages.data[0].attributes.HeaderDynamicZone.map((item, i) => (
                <>
                  {item.__typename == "ComponentBlockDzHeader01" && <Suspense fallback={<div className="min-h-screen w-full bg-pwhite dark:bg-pgray-800"></div>}><CaseStudyPageHeader1Comp mode={mode} data={item}/></Suspense>}
                </>
              ))}

              {caseStudyPages.data[0].attributes.DynamicZone.map((item, i) => (
                <>
                  {/* {item.__typename == "ComponentBlockUseCase1Block" && <Suspense fallback={<div></div>}><UseCasesPageDynamicZone1Comp mode={mode} data={item}/></Suspense>} */}
                  {item.__typename == "ComponentBlockDynamicZone2Block" && <Suspense fallback={<div></div>}><CaseStudyPageDynamicZone2Comp mode={mode} data={item}/></Suspense>}
                  {item.__typename == "ComponentBlockDynamicZone3Block" && <Suspense fallback={<div></div>}><CaseStudyPageDynamicZone4Comp mode={mode} data={item}/></Suspense>}
                </>
              ))}
              {caseStudyPages.data[0].attributes.ShowFormBottom && <Suspense fallback={<div></div>}><CaseStudyPageContactUsComp mode={mode}/></Suspense>}
              <MediumComp mode={mode} />
            </>
          );
        }}
      </Query>
    </>
  );
}

export default CaseStudiesPage;