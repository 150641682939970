import { lazy, Suspense } from "react";
// import { useParams, Navigate } from "react-router-dom";
import gql from "graphql-tag";
import Query from "../utils/Query";
import MediumComp from './MediumComp';

const MetaComp = (lazy(() => (import('../comp/MetaComp'))));
const HeaderComp = (lazy(() => (import('../comp/HeaderComp2'))));
const FeaturesBlocksComp = (lazy(() => (import('../comp/FeaturesBlocksComp'))));

function TokenPage(props) {
  const mode = props.mode ? props.mode : "light";

  // let { slug } = useParams();

  const TOKENPAGE_QUERY = gql`
    query TokenPages {
      token {
        data {
          attributes {
            Meta {
              Title
              Desc
              Img {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            Header {
              Tag {
                Title
              }
              Title
              SubTitle
              Text2
              Button1 {
                Disable
                External
                Text
                URL 
              }
              Button2 {
                Disable
                External
                Text
                URL 
              }
              BackgroundImage {
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                Text
              }
            }
            Features {
              Title
              SubTitle
              Feature {
                Tag
                Title
                Text
                Text2
                Text3
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                ImageText
                ButtonDisable
                ButtonExternal
                ButtonText
                ButtonURL
              }
            }
          }
        }
      }
    }
  `;

  return (
    <>
      <Query query={TOKENPAGE_QUERY} id={null}>
        {({ data: { token } }) => {
          // if (seoBlockchainPagess.data.length <= 1) {
          //   { console.log(seoBlockchainPagess) }
          // }

          return (
            <>
              {/* {seoBlockchainPagess.data.length == 0 && 
                <Navigate replace to="/404" />
              } */}
              <Suspense fallback={<div></div>}><MetaComp mode={mode} data={token.data.attributes.Meta}/></Suspense>
              <Suspense fallback={<div></div>}><HeaderComp mode={mode} data={token.data.attributes.Header}/></Suspense>
              <Suspense fallback={<div></div>}><FeaturesBlocksComp mode={mode} data={token.data.attributes.Features[0]}/></Suspense>
              <Suspense fallback={<div></div>}><FeaturesBlocksComp mode={mode} data={token.data.attributes.Features[1]}/></Suspense>
              {/* {console.log(seoBlockchainPagess)} */}
              {/* <div className="dark:bg-pgray-800 bg-pgray-50 flex">
                  <div className="w-full pt-8 pb-8 sm:pt-16 sm:pb-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-4 sm:px-6 lg:px-6 border-t dark:border-pgray-600 border-pgray-200"></div>
                  </div>
                </div> */}
              <MediumComp mode={mode} />
            </>
          );
        }}
      </Query>
    </>
  );
}

export default TokenPage;