import { lazy, Suspense } from "react";

import MediumComp from '../comp/MediumComp';

const PricingPageMetaComp = (lazy(() => (import('../comp/PricingPageMetaComp'))));
const PricingPageComp1 = (lazy(() => (import('../comp/PricingPageComp1'))));
const PricingPageFAQComp = (lazy(() => (import('../comp/PricingPageFAQComp'))));
const PricingPageCTABlockComp = (lazy(() => (import('../comp/PricingPageCTABlockComp'))));

function DataLakesPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Suspense fallback={<div></div>}><PricingPageMetaComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><PricingPageComp1 mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><PricingPageCTABlockComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><PricingPageFAQComp mode={mode} /></Suspense>
      

      {/* <MediumComp mode={mode} /> */}
    </>
  );
}

export default DataLakesPage;