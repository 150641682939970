import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import clsx from 'clsx';

function NavbarComp(props) {
  const mode = props.mode ? props.mode : "light";
  // const [dropdownOpen, setDropdownOpen] = useState(false)
  // const [dropdown, setDropdown] = useState(0)
  // const onError = useCallback(() => console.log("Error callback fired!"), []);
  // const { data } = useQuery(NAVBAR2023_QUERY, { onError });
  // let location = useLocation();

  const handleClickScroll = (e) => {
    const element = document.getElementById(e);
    if (element) {
      console.log(element)
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    // <Query query={NAVBAR2023_QUERY} id={null}>
    //   {({ data: { navbar2023 } }) => {
    //     return (
          <div className="hidden fixed left-1 top-0 bottom-0 z-30 md:flex">
            <div className="flex-none my-auto">
              <div className="p-3 flex flex-col gap-3 rounded-full backdrop-blur-xl group dark:hover:bg-pgray-900/10">
                <Link to="#benefits" onClick={() => handleClickScroll('benefits')}><div className={clsx("w-2 h-2 rounded-full hover:bg-pblue-500", { "bg-pgray-400 dark:bg-pgray-500": props.benefits }, { "bg-pgray-300 dark:bg-pgray-700": !props.benefits })}></div></Link>
                <Link to="#usecases" onClick={() => handleClickScroll('usecases')}><div className={clsx("w-2 h-2 rounded-full", { "bg-pgray-400 dark:bg-pgray-500": props.extra1Comp }, { "bg-pgray-300 dark:bg-pgray-700": !props.extra1Comp })}></div></Link>
                <Link to="#howtouse" onClick={() => handleClickScroll('howtouse')}><div className={clsx("w-2 h-2 rounded-full", { "bg-pgray-400 dark:bg-pgray-500": props.extra2Comp }, { "bg-pgray-300 dark:bg-pgray-700": !props.extra2Comp })}></div></Link>
                <Link to="#datalakes" onClick={() => handleClickScroll('datalakes')}><div className={clsx("w-2 h-2 rounded-full", { "bg-pgray-400 dark:bg-pgray-500": props.apiComp }, { "bg-pgray-300 dark:bg-pgray-700": !props.apiComp })}></div></Link>
                <Link to="#spotlight" onClick={() => handleClickScroll('spotlight')}><div className={clsx("w-2 h-2 rounded-full", { "bg-pgray-400 dark:bg-pgray-500": props.spotlightComp }, { "bg-pgray-300 dark:bg-pgray-700": !props.spotlightComp })}></div></Link>
                <Link to="#contactus" onClick={() => handleClickScroll('contactus')}><div className={clsx("w-2 h-2 rounded-full", { "bg-pgray-400 dark:bg-pgray-500": props.contactUsComp }, { "bg-pgray-300 dark:bg-pgray-700": !props.contactUsComp })}></div></Link>
              </div>
            </div>

          </div>
    //     );
    //   }}
    // </Query>
  );
}

export default NavbarComp;
