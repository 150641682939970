import React from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";
import HoverShinyEffect from "../utils/HoverShinyEffect";
// import { Helmet } from 'react-helmet-async';

const HOMEPAGE2023BLOCKCHAINS_QUERY = gql`
  query HomePage {
    home2023 {
      data {
        attributes {
          SupportedBlockchains {
            __typename,
            ... on ComponentBlockBlockWithBlockchains {
              Title
              Tag {
                Title
              }
              Image {
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                Text
              }
            }
          }
        }
      }
    }
  }
`;

const HOMEPAGE2023USECASES_QUERY = gql`
  query HomePage {
    home2023 {
      data {
        attributes {
          UseCases {
            __typename,
            ... on ComponentBlockHomeBlock2023 {
              Icon,
              PreTitle,
              Title,
              SubTitle,
              Block {
                Tag,
                Title,
                Text,
                Text2,
                Text3,
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                ImageText,
                ButtonDisable,
                ButtonExternal,
                ButtonText,
                ButtonURL,
                Icon
              }
            }
          }
        }
      }
    }
  }
`;


function HomePageBenefitsComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <div className="flex dark:bg-pgray-800 bg-pgray-200 relative" id="blockchains">
        <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
          <div className="z-20 relative">
            <Query query={HOMEPAGE2023BLOCKCHAINS_QUERY} id={null}>
              {({ data: { home2023 } }) => {
                return (
                  <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                    <div className="shrink-0 mr-5 sm:mx-10 w-5 relative">
                      <div className="h-full flex flex-col relative">
                        <div className="bg-pgray-300 dark:bg-pgray-500 w-1 h-20 mx-auto rounded-b-xl"></div>
                        <div className="ml-2 absolute text-pgray-300 dark:text-pgray-500 -bottom-2 -left-[1px]">
                          <div className="absolute -right-2">
                            <svg className="" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g transform="translate(-386.000000, -290.000000)" fill="#8FA1B9" stroke="currentColor" stroke-width="4">
                                  <rect id="Rectangle" x="388" y="292" width="16" height="16" rx="8"></rect>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <svg width="64px" height="128px" viewBox="0 0 70 134" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                              <g transform="translate(-393.000000, -2635.000000)" stroke="currentColor" stroke-width="4">
                                <path d="M460,2638 C460,2659.33333 449.333333,2680.66667 428,2702 C406.666667,2723.33333 396,2744.66667 396,2766" id="Path"></path>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="pb-20 bg-gradient-to-b from-pgray-300 dark:from-pgray-500 w-1 mx-auto grow"></div>
                      </div>
                    </div>

                    <div className="mr-10 w-5 relative">
                      <div className="h-full flex flex-col relative">
                      </div>
                    </div>

                    <div className="grow sm:text-center lg:text-left mt-12 max-w-none">
                      <h1 className="relative tracking-tight dark:text-pwhite/70 text-pgray-900 text-xl flex flex-row gap-2">
                        <span className="shrink-0 uppercase">{home2023.data.attributes.SupportedBlockchains.Title}</span>
                        {home2023.data.attributes.SupportedBlockchains.Tag.Title &&
                          <div className="hidden sm:block flex space-x-8">
                            <div className="font-regular text-sm sm:text-sm text-pgreen-500 mb-5 leading-tight px-2 py-1 border border-pgreen-500 rounded-full">{home2023.data.attributes.SupportedBlockchains.Tag.Title}</div>
                          </div>
                        }
                      </h1>
                      <div className="flex flex-row flex-wrap gap-8 mt-4">
                        {home2023.data.attributes.SupportedBlockchains.Image.map((item, i) => (
                          <img src={process.env.REACT_APP_BACKEND_URL + item.LightImage.data.attributes.url} className="w-16 h-16" />
                        ))}
                      </div>
                    </div>
                  </main>
                );
              }}
            </Query>
          </div>

        </div>
      </div>
      <Query query={HOMEPAGE2023USECASES_QUERY} id={null}>
        {({ data: { home2023 } }) => {
          return (
            <>
              <div className="flex bg-pgray-200 dark:bg-pgray-800 relative" id="usecases">
                <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                  <div className="z-20 relative">
                    <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                      <div className="mr-5 sm:mx-10 w-5 relative">
                        <div className="h-full flex flex-col relative">
                          <div className="bg-pgray-300 dark:bg-pgray-500 w-1 h-[7.5rem] mx-auto rounded-b-xl"></div>
                          <div className="text-pgreen-500 dark:text-pgray-300 relative my-10 flex-none">
                            <div className="h-6 w-6 animate-pulse" dangerouslySetInnerHTML={{ __html: home2023.data.attributes.UseCases.Icon }}></div>
                            <div className="absolute top-0 left-0 w-6 h-6 rounded-full bg-pgreen-500 dark:bg-pgray-400 blur-lg"></div>
                          </div>

                          <div className="pb-16 bg-gradient-to-b from-pgray-300 dark:from-pgray-500 w-1 mx-auto rounded-t-xl grow"></div>
                        </div>
                      </div>
                      <div className="grow lg:text-left mt-20">
                        <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600/60 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0 mb-2 uppercase">
                          {home2023.data.attributes.UseCases.PreTitle}
                        </p>
                        <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-5xl max-w-">
                          {home2023.data.attributes.UseCases.Title}
                        </h1>
                      </div>
                    </main>
                  </div>

                </div>
              </div>
              <div className="relative bg-pgray-200 dark:bg-pgray-800">
                <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-6 relative z-20">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative">

                    {home2023.data.attributes.UseCases.Block.map((item, i) => (
                      <div key={i} className="bg-pwhite dark:bg-pgray-800 border border-pgray-300 dark:border-pgray-600 rounded-xl overflow-hidden relative">
                        <HoverShinyEffect className="z-[13]" />
                        <div className="w-full p-5 flex flex-row">
                          <div className="h-6 w-6 mb-1 mr-2 text-pgray-300 flex-none" dangerouslySetInnerHTML={{ __html: item.Text3 }}></div>
                          <div className="flex flex-col shrink">
                            <div className="text-2xl font-medium text-pgray-900 dark:text-pwhite mb-2">{item.Title}</div>
                            <div className="text-xl text-pgray-700/70 dark:text-pwhite/70 mb-4">{item.Text}</div>
                            {!item.ButtonDisable && 
                              <div className="flex items-center">
                                <a href={item.ButtonURL} className="z-[30] relative transition-colors ease-in-out bg-pgray-100 hover:bg-pgray-200 dark:bg-pgray-500/50 hover:dark:bg-pgray-500 rounded-full h-9 flex">
                                  <div className="relative pl-3 pr-1.5 my-auto flex flex-row items-center justify-center">
                                    <div className="font-base text-pgray-800 dark:text-pwhite mr-3">{item.ButtonText}</div>
                                    <div className="text-pgray-900 dark:text-pwhite rounded-full w-8 h-6 flex items-center justify-center">
                                      <div className="h-4 w-4" dangerouslySetInnerHTML={{ __html: item.Icon }}></div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                </div>


                <div className='-rotate-25 absolute -top-[800px] -left-20 w-[50rem] h-[20rem] blur-4xl bg-gradient-to-r from-pgreen-400 to-pblue-400 dark:from-pgreen-500 dark:to-pblue-500 rounded-full'></div>
                <div className='absolute inset-y-[64rem] sm:inset-y-[7rem] md:inset-y-[24rem] lg:inset-y-[7rem] left-0 w-full lg:w-full bg-cone h-192 z-[11] scale-150'></div>

                <div className="flex relative">
                  <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                    <div className="z-20 relative">
                      <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                        <div className="mr-5 sm:mx-10 w-5 relative">
                          <div className="h-full flex flex-col relative">
                            <div className="bg-gradient-to-t from-pgray-300 dark:from-pgray-500 w-1 h-20 mx-auto"></div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>
                  {/* <div className='absolute bottom-0 md:inset-y-0 left-0 w-full lg:w-3/5 backdrop-blur-md dark:bg-pgray-800 bg-pgray-50 dark:bg-opacity-50 bg-opacity-30 h-4/6 md:h-full z-10'></div> */}

                  {/* <div className='absolute -top-[400px] left-0 w-[50rem] h-[50rem] blur-3xl bg-gradient-to-r from-pgreen-500 to-pblue-500 z-9 rounded-full'></div> */}
                  {/* <div className='absolute bottom-0 md:inset-y-0 left-0 w-full lg:w-full backdrop-blur-md bg-pgray-800 dark:bg-opacity-50 bg-opacity-30 h-full md:h-full z-10'></div> */}
                </div>

              </div>
            </>
          );
        }}
      </Query>
    </>

  );
}

export default HomePageBenefitsComp;