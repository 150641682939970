import { lazy, Suspense } from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";

const MetaComp = (lazy(() => (import('../comp/MetaComp'))));


function RoadmapPage(props) {
  const mode = props.mode ? props.mode : "light";

  const ROADMAPPAGE_QUERY = gql`
    query RoadmapPage {
      roadmap {
        data {
          attributes {
            Meta {
              Title
              Desc
              Img {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            URL
          }
        }
      }
    }
  `;

  return (
    <>
      <Query query={ROADMAPPAGE_QUERY} id={null}>
        {({ data: { roadmap } }) => {
          return (
            <>
              <Suspense fallback={<div></div>}><MetaComp mode={mode} data={roadmap.data.attributes.Meta}/></Suspense>
              <div className="flex dark:bg-pgray-900 bg-pwhite relative mt-20">
                <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                  <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                    {/* <div className="mr-5 sm:mx-10 w-5 relative"> */}
                    <iframe className="w-full h-[600px] rounded-xl" src={roadmap.data.attributes.URL}></iframe>
                    {/* </div> */}
                  </main>
                </div>
              </div>
            </>
          );
        }}
      </Query>
    </>
  );
}

export default RoadmapPage;