import { lazy, Suspense } from "react";

import MediumComp from './MediumLineComp';

const CommunityPageHeaderComp = (lazy(() => (import('./CommunityPageHeaderComp'))));
const CommunityPageComp1 = (lazy(() => (import('./CommunityPageComp1'))));
// const DataLakesPageHowitWorksComp = (lazy(() => (import('./DataLakesPageHowitWorksComp'))));
// const DataLakesPageFAQComp = (lazy(() => (import('./DataLakesPageFAQComp'))));
// const DataLakesPageCTABlockComp = (lazy(() => (import('./DataLakesPageCTABlockComp'))));

function CommunityPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Suspense fallback={<div></div>}><CommunityPageHeaderComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><CommunityPageComp1 mode={mode} /></Suspense> 
      {/* <Suspense fallback={<div></div>}><DataLakesPageHowitWorksComp mode={mode} /></Suspense>

      <Suspense fallback={<div></div>}><DataLakesPageFAQComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><DataLakesPageCTABlockComp mode={mode} /></Suspense> */}

      <MediumComp mode={mode} />
    </>
  );
}

export default CommunityPage;