import React, { useRef, useEffect} from "react";

// const color = ""
function HoverShinyEffect(props) {
  const containerRef = useRef(null);
  const href = props.html;

  useEffect(() => {
    function mouseMoveEvent(e) {
      const { x, y } = containerRef.current.getBoundingClientRect();
      containerRef.current.style.setProperty('--px', e.clientX - x);
      containerRef.current.style.setProperty('--py', e.clientY - y);
      // color && containerRef.current.style.setProperty('--color', color);
    }
    containerRef.current.addEventListener('mousemove', mouseMoveEvent);

    return () => {
      containerRef.current.removeEventListener('mousemove', mouseMoveEvent);
    };
  }, []);

  return props.html ? (<a href="google" ref={containerRef} className="shiny_effect z-[25]" ></a>) : (<div ref={containerRef} className="shiny_effect z-[25]" ></div>);
}

export default HoverShinyEffect;