import { lazy, Suspense } from "react";
import { useParams, Navigate } from "react-router-dom";
import gql from "graphql-tag";
import Query from "../utils/Query";
import MediumComp from './MediumComp';

const UseCasesPageMetaComp = (lazy(() => (import('../comp/UseCasesPageMetaComp'))));
const UseCasesPageHeaderComp = (lazy(() => (import('../comp/UseCasesPageHeaderComp'))));
const UseCasesPageDynamicZone1Comp = (lazy(() => (import('../comp/UseCasesPageDynamicZone1Comp'))));
const UseCasesPageDynamicZone2Comp = (lazy(() => (import('../comp/UseCasesPageDynamicZone2Comp'))));
const UseCasesPageDynamicZone3Comp = (lazy(() => (import('../comp/UseCasesPageDynamicZone3Comp'))));
const UseCasesPageDynamicZone4Comp = (lazy(() => (import('../comp/UseCasesPageDynamicZone4Comp'))));
const UseCasesPageDynamicZone5Comp = (lazy(() => (import('../comp/UseCasesPageDynamicZone5Comp'))));
const UseCasesPageDynamicZone6Comp = (lazy(() => (import('../comp/UseCasesPageDynamicZone6Comp'))));
const UseCasesPageDynamicZone7Comp = (lazy(() => (import('../comp/UseCasesPageDynamicZone7Comp'))));

function UseCasesPage(props) {
  const mode = props.mode ? props.mode : "light";

  let { slug } = useParams();

  const USECASESPAGES_QUERY = gql`
    query UseCasesPage($slug: String!) {
      useCasesPages(filters: { Slug: { eq: $slug } }) {
        data {
          attributes {
            Slug
            Meta {
              Title
              Desc
              Img {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            Header {
              Title
              SubTitle
              Image1 {
                data {
                  attributes {
                    url
                  }
                }
              }
              Image2 {
                data {
                  attributes {
                    url
                  }
                }
              }
              Image3 {
                data {
                  attributes {
                    url
                  }
                }
              }
              Image4 {
                data {
                  attributes {
                    url
                  }
                }
              }
              Image1Text
              Image2Text
              Image3Text
              Image4Text
              Buttons {
                Text
                URL
                Disable
                External
                Icon
              }
            }
            IntroText
            DynamicZone {
              __typename
              ... on ComponentBlockUseCase1Block{
                Title
                Block {
                  Title
                  SubTitle
                  PreLinksText
                  Links {
                    Icon
                    Text
                    URL
                    External
                    Disable
                  }
                  LightImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
              }
              ... on ComponentBlockUseCase2Block{
                Title
                Block {
                  Title
                  SubTitle
                  PreLinksText
                  Links {
                    Icon
                    Text
                    URL
                    External
                    Disable
                  }
                  LightImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
                Title2
                SubTitle2
                Block2 {
                  Title
                  SubTitle
                  PreLinksText
                  Links {
                    Icon
                    Text
                    URL
                    External
                    Disable
                  }
                  LightImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
              }
              ... on ComponentBlockUseCase3Block{
                Title
                Block {
                  Title
                  SubTitle
                  PreLinksText
                  Links {
                    Icon
                    Text
                    URL
                    External
                    Disable
                  }
                  LightImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
              }
              ... on ComponentBlockUseCase4Block{
                Title
                Block {
                  TitleLinkText
                  SubTitle
                  URL
                  Disable
                  External
                  Icon
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
              }
              ... on ComponentBlockUseCase5Block{
                Title
                Block {
                  Title
                  SubTitle
                  PreLinksText
                  Links {
                    Icon
                    Text
                    URL
                    External
                    Disable
                  }
                  LightImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
              }
              ... on ComponentBlockUseCase6Block{
                Title
                Block {
                  Title
                  SubTitle
                  Title2
                  SubTitle2
                  PreLinksText
                  Links {
                    Icon
                    Text
                    URL
                    External
                    Disable
                  }
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
                Block2 {
                  Title
                  SubTitle
                  Title2
                  SubTitle2
                  PreBlocksText
                  Blocks {
                    Title
                    Icon
                    Text
                    URL
                    External
                    Disable
                  }
                }
              }
              ... on ComponentBlockUseCase7Block{
                Title
                Block {
                  TitleLinkText
                  SubTitle
                  Icon
                  URL
                  External
                  Disable
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
                Title2
                Block2 {
                  TitleLinkText
                  SubTitle
                  Icon
                  URL
                  External
                  Disable
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ImageText
                }
              }
            }
          }
        }
      }
    }
  `;

  return (
    <>
      <Query query={USECASESPAGES_QUERY} id={null} slug={slug}>
        {({ data: { useCasesPages } }) => {
          return (
            <>
              {useCasesPages.data.length == 0 && 
                <Navigate replace to="/404" />
              }
              {/* {console.log(useCasesPages.data[0].attributes)} */}
              <Suspense fallback={<div></div>}><UseCasesPageMetaComp mode={mode} data={useCasesPages.data[0].attributes.Meta}/></Suspense>
              <Suspense fallback={<div className="min-h-screen w-full bg-pwhite dark:bg-pgray-800"></div>}><UseCasesPageHeaderComp mode={mode} data={useCasesPages.data[0].attributes.Header}/></Suspense>
              <div className="dark:bg-pgray-900 bg-pwhite flex">
                <div className="w-full">
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6 mb-16">
                    <div className="max-w-4xl mx-auto text-center dark:text-pwhite text-2xl sm:text-4xl dark:opacity-70" dangerouslySetInnerHTML={{ __html: useCasesPages.data[0].attributes.IntroText }}/>
                  </div>
                </div>
              </div>
              {useCasesPages.data[0].attributes.DynamicZone.map((item, i) => (
                <>
                  {item.__typename == "ComponentBlockUseCase1Block" && <Suspense fallback={<div></div>}><UseCasesPageDynamicZone1Comp mode={mode} data={item}/></Suspense>}
                  {item.__typename == "ComponentBlockUseCase2Block" && <Suspense fallback={<div></div>}><UseCasesPageDynamicZone2Comp mode={mode} data={item}/></Suspense>}
                  {item.__typename == "ComponentBlockUseCase3Block" && <Suspense fallback={<div></div>}><UseCasesPageDynamicZone3Comp mode={mode} data={item}/></Suspense>}
                  {item.__typename == "ComponentBlockUseCase4Block" && <Suspense fallback={<div></div>}><UseCasesPageDynamicZone4Comp mode={mode} data={item}/></Suspense>}
                  {item.__typename == "ComponentBlockUseCase5Block" && <Suspense fallback={<div></div>}><UseCasesPageDynamicZone5Comp mode={mode} data={item}/></Suspense>}
                  {item.__typename == "ComponentBlockUseCase6Block" && <Suspense fallback={<div></div>}><UseCasesPageDynamicZone6Comp mode={mode} data={item}/></Suspense>}
                  {item.__typename == "ComponentBlockUseCase7Block" && <Suspense fallback={<div></div>}><UseCasesPageDynamicZone7Comp mode={mode} data={item}/></Suspense>}
                </>
              ))}
              {/* <div className="dark:bg-pgray-800 bg-pgray-50 flex">
                  <div className="w-full pt-8 pb-8 sm:pt-16 sm:pb-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-4 sm:px-6 lg:px-6 border-t dark:border-pgray-600 border-pgray-200"></div>
                  </div>
                </div> */}
              <MediumComp mode={mode} />
            </>
          );
        }}
      </Query>
    </>
  );
}

export default UseCasesPage;