import { useState, useEffect, useRef } from "react";
import { useScramble } from 'use-scramble';
import { useNavigate } from "react-router-dom";

const texts = [
  "Daaaanyyy oh shi ohhh fkcc, halp... we drop everything",
  "ohhh it's change..",
  "is still doesn't work?",
  "try restart page",
  "or continue click here",
  "you know if you click - text change?",
  "😳 are you mad?",
  "just restart page",
  "maybe try write message to Danny he know what to do",
  "or restart page",
  "or wait, we also wait",
  "when binance?",
  "oh oh i have idea: what about write Anatoly",
  "my stars, the cosmic flux is shifting! Quick, someone fetch the crystal ball",
  "where is Alan?",
  "it's happening again... the quantum predictions are in a state of flux",
  "is it still not crystal clear? Perhaps a cosmic reboot is in order",
  "try to recalibrate your psychic antennae or maybe just take a deep cosmic breath",
  "did you know Alan and John called raccon-mascot a BARSIQ?",
  "or, if all else fails, click your heels together three times and see if reality obeys",
  "Toomas is one of the best CEO, good flexible lead",
  "i think this doesn't work, just restart page or reality",
  "did you know that sometimes clicking can slow the temporal ripples?",
  "who is John?",
  "let's simplify things: initiate a cosmic restart protocol",
  "perhaps a telepathic message to Danny is in order; he's been through this cosmic maze before",
  "in june 2024 we make [REDACTED]",
  "or, you know, you could always try restarting the entire space-time continuum",
  "in the grand tapestry of the cosmos, sometimes we must simply wait, as the stars do",
  "you know all codes on background of images on website is writed on brainfuck(this is such a programming language if you did not know)",
  "when will Binance align with the cosmic order? The stars remain enigmatic",
  "oh, oh, I have a brilliant thought! What if we reach out to Anatoly and tap into his cosmic wisdom?",
  "🤡",
  "%username% are you still clicking?",
  "a twist in the cosmic threads! Seek the hidden path, it holds the key.",
  "you still clicking?",
  "ok i start loop at the begin",
  "Daaaanyyy oh shi ohhh fkcc, halp... we drop everything",
  "ohhh it's change..",
  "is still doesn't work?",
  "oh new message and you still clicking 😬",
  "the signs are shifting like sands in an interstellar desert...",
  "still caught in the temporal riddle? try restart page",
  "is it the winds of fate or mere chance that guides our steps?",
  "one of the old version of website clock icons show actual time",
  "seek the Oracle of Serenity for enlightenment, young traveler.",
  "gaze upon the constellations; they may reveal the answers you seek",
  "or, venture deeper into the quantum realm by clicking this enigmatic link",
  "Daaaaanny he/she still clicking",
  "did you fathom that each click may ripple through the astral plane?",
  "let us simplify the arcane: initiate a celestial reset ritual",
  "Alan is that you?",
  "restart page",
  "restart page",
  "restart page",
  "perhaps a telepathic missive to Danny will unveil the celestial code",
  "or, take a leap into the cosmic abyss and reboot existence itself",
  "in the vast expanse of time, we often find ourselves in a cosmic pause, like stars in twilight",
  "when will Binance align with the celestial harmonics? The omens are unclear",
  "these messages have a playful and mystic tone",
  "maybe i change a little tone",
  "web3.0 for the tec",
  "in the depths of uncertainty, find solace in the stillness of your own heart",
  "the cosmic dance continues, and we are but humble spectators",
  "as the moon wanes and waxes, so too does the wheel of fortune turn",
  "the Oracle's cryptic gaze pierces the veil of time, revealing glimpses of what's to come",
  "embrace the journey, for it is in the unknown that we discover our true selves",
  "the river of time flows, and its currents carry us toward our destiny",
  "web3.0 is more about make web more understable for machines",
  "the stars have whispered secrets of a great change on the horizon.",
  "in the realm of possibilities, a new chapter is being written.",
  "do you hear the cosmic winds? They carry tidings of transformation.",
  "the dance of time and destiny is an intricate tapestry woven by the cosmos.",
  "as the moon wanes and waxes, so do the fates of mortals ebb and flow.",
  "but what about web4.0? 🤔",
  "web4.0 is what you wanna: decentralis(z)ations, ai integrations, ar/vr, seamless crossplatforms ux.",
  "beneath the celestial canopy, the threads of fate are spun with purpose.",
  "the oracle of ages sees a convergence of paths leading to a pivotal moment.",
  "but when?",
  "i think right now",
  "in the cosmic symphony, every note is a message from the universe.",
  "seek not to control the stars, but to understand their celestial dance.",
  "where is the end?",
  "the constellations above mirror the patterns of life below; their stories intertwine.",
  "a riddle of time and space: Where does destiny end, and choice begin?",
  "in the cosmic library, the book of your fate is being penned with every heartbeat.",
  "the wisest sages consult the heavens, for they hold the secrets of past, present, and future.",
  "when the tides of the universe shift, destinies are rewritten in stardust.",
  "i don't know 🤷‍♂️",
  "Systems are hierarchical. Each system is part of a larger system. An organization can only exist when it is a subsystem of a larger system, communicates with other systems and builds relationships with them.",
  "the celestial clock ticks inexorably forward, counting the moments of our lives.",
  "embrace the unknown, for in it lies the potential for greatness.",
  "42?",
  "this is easy: think about the answer itself, 42 is just a play of sounds - 'forty-two'",
  "the computer that gave the answer was called Deep Thought, playing with sound you can get the result of Thoughty Two",
  "very british joke, about pronounce",
  "the cosmic tapestry weaves the threads of our existence into a masterpiece.",
  "the stars are but beacons in the vast sea of the cosmos, guiding our way.",
  "maybe one day i just add here AI to generate messages",
  "right now this messages already predifined",
  "the universe whispers its truths to those who listen with an open heart.",
  "to glimpse the future, one must first understand the past and present.",
  "the mystic's journey is a quest for enlightenment through cosmic revelation.",
  "in the realm of dreams, whispers of the future echo through the corridors of the mind.",
  "a riddle wrapped in a mystery, concealed in the enigma of the unknown.",
  "the stars align to reveal secrets known only to the cosmos.",
  "you know a may force you to restart page?",
  "i think this is be our one the final messages",
  "see you next time, bye",
  "hi, again now we outside the loop"
]

const Text = ({ }) => {
  const [index, setIndex] = useState(0)
  const loopRef = useRef()
  const navigate = useNavigate();

  const { ref, replay } = useScramble({
    text: texts[index],
    scramble: 18, 
    playOnMount: true,
  })

  function Test() {
    clearInterval(loopRef.current)
    loopRef.current = setTimeout(() => {
      setIndex((index) => (index < texts.length - 1 ? index + 1 : 0))
    }, 200)
  }

  return <span 
    className="fortune-teller nephomancer cursor-pointer" 
    ref={ref} 
    onClick={() => {
      index < texts.length - 1 ? Test() : navigate("/")
    }} />
}

function Error503Page(props) {
  const mode = props.mode ? props.mode : "light";

  const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })
  var [date, setDate] = useState(new Date());

  const mouseMoveHandler = (event) => {
    setMouseCoordinates({
      x: event.clientX,
      y: event.clientY
    });
  }
  useEffect(() => {
    window.addEventListener('mousemove', mouseMoveHandler);
    return (() => {
      window.removeEventListener('mousemove', mouseMoveHandler);
    })
  }, [])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }

  });
  return (
    <div className="relative flex min-h-screen 2xl:min-h-[1000px] dark:bg-pgray-900 bg-pwhite">
      <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4">
        <div className="relative z-20 pb-8 max-w-full lg:max-w-3xl lg:w-full">
          <h1 className="relative tracking-tight font-extrabold font-display dark:text-pwhite sm:max-w-xl sm:mx-auto lg:mx-0 text-pgray-900 text-6xl sm:text-6xl md:text-8xl lg:text-9xl">
            <span className="block">503</span>
          </h1>
          <p className="mt-3 text-3xl sm:text-4xl dark:text-pwhite text-pgray-900 opacity-60 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 sm:leading-relaxed">
            The Server is Down for Maintenance
          </p>
          <p className="h-20 mt-3 text-base dark:text-pwhite text-pgray-900 opacity-60 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 sm:leading-relaxed">
            <Text />
          </p>
        </div>
      </div>
      <div className='absolute bottom-0 right-16 sm:text-[4rem] font-bold font-display dark:text-pwhite text-pgray-900 opacity-[0.02] max-w-5xl text-right'>
        <div>
          {window.navigator.userAgent}
        </div>
        <div>
          {
            windowSize[0] + "px"
            + " " +
            windowSize[1] + "px"
            + " " +
            scrollPosition.scrollX + ""
            + " " +
            scrollPosition.scrollY + ""
            + " " +
            mouseCoordinates.x + ""
            + " " +
            mouseCoordinates.y + ""
          }
        </div>
        <div>
          {date.toLocaleDateString()} {date.toLocaleTimeString()}
        </div>

      </div>
    </div>
  );
}

export default Error503Page;
