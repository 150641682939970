import { useMemo, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  const [playAnimation, setPlayAnimation] = useState(false);
  let location = useLocation();

  let hashElement = useMemo(() => {
    let hash = location.hash;
    const removeHashCharacter = (str) => {
      const result = str.slice(1);
      return result;
    };

    if (hash) {
      let element = document.getElementById(removeHashCharacter(hash));
      return element;
    } else {
      return null;
    }
  }, [location]);

  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
      console.log(location.hash)

      const removeHashCharacter = (str) => {
        const result = str.slice(1);
        return result;
      };
      
      // console.log('loaded', location, hashElement, document.readyState)
      if (location.hash) {
        // console.log('loaded', location, hashElement, document.readyState)
        setTimeout(() => {
          // alert('test'),
          // hashElement.scrollIntoView({
            document.getElementById(removeHashCharacter(location.hash)).scrollIntoView({
            behavior: "smooth",
            // block: "end",
            inline: "nearest",
          })
        }, 1000);
      }
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
      if (hashElement) {
        console.log(location.hash)
        setTimeout(() =>
          // alert('test'),
          hashElement.scrollIntoView({
            behavior: "smooth",
            // block: "end",
            inline: "nearest",
          }), 0);
      }
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [hashElement]);

  return null
};

export default ScrollToHashElement;