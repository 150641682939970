import { lazy, Suspense } from "react";

import MediumComp from '../comp/MediumComp';

const TsunamiPageHeaderComp = (lazy(() => (import('../comp/TsunamiPageHeaderComp'))));
const TsunamiPageSupportedNetworksComp = (lazy(() => (import('../comp/TsunamiPageSupportedNetworksComp'))));
const TsunamiPageFeaturesComp = (lazy(() => (import('../comp/TsunamiPageFeaturesComp'))));
const TsunamiPageTestimonialComp = (lazy(() => (import('../comp/TsunamiPageTestimonialComp'))));
const TsunamiPageFeaturesComp2 = (lazy(() => (import('../comp/TsunamiPageFeaturesComp2'))));
const TsunamiPageFAQComp = (lazy(() => (import('../comp/TsunamiPageFAQComp'))));
const TsunamiPageCTABlockComp = (lazy(() => (import('../comp/TsunamiPageCTABlockComp'))));

function TsunamiPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Suspense fallback={<div></div>}><TsunamiPageHeaderComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><TsunamiPageSupportedNetworksComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><TsunamiPageFeaturesComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><TsunamiPageTestimonialComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><TsunamiPageFeaturesComp2 mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><TsunamiPageFAQComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><TsunamiPageCTABlockComp mode={mode} /></Suspense>
      <MediumComp mode={mode} />
    </>
  );
}

export default TsunamiPage;