import { lazy, Suspense } from "react";

// import MediumComp from '../comp/MediumComp';

const TOCPageMetaComp = (lazy(() => (import('../comp/TOCPageMetaComp'))));
// const DataLakesPageFeaturesComp = (lazy(() => (import('../comp/DataLakesPageFeaturesComp'))));
const TOCPageComp1 = (lazy(() => (import('../comp/TOCPageComp1'))));
// const PricingPageFAQComp = (lazy(() => (import('../comp/PricingPageFAQComp'))));
// const PricingPageCTABlockComp = (lazy(() => (import('../comp/PricingPageCTABlockComp'))));

function TOCPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Suspense fallback={<div></div>}><TOCPageMetaComp mode={mode} /></Suspense>
      {/* <Suspense fallback={<div></div>}><DataLakesPageFeaturesComp mode={mode} /></Suspense> */}
      <Suspense fallback={<div></div>}><TOCPageComp1 mode={mode} /></Suspense>
      
    </>
  );
}

export default TOCPage;