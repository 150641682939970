import React from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";
import HoverShinyEffect from "../utils/HoverShinyEffect";

const HOME2023HOWTOTUSE_QUERY = gql`
  query Home2023Page {
    home2023 {
      data {
        attributes {
          HowToUse {
            __typename,
            ... on ComponentBlockHomeBlockimage2023 {
              Icon
              PreTitle,
              Title,
              SubTitle
              Block {
                Tag,
                Title,
                Text,
                Text2,
                Text3,
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                ImageText,
                ButtonDisable,
                ButtonExternal,
                ButtonText,
                ButtonURL,
                Icon
              }
            }
          }
        }
      }
    }
  }
`;

function HomePageBenefitsComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Query query={HOME2023HOWTOTUSE_QUERY} id={null}>
        {({ data: { home2023 } }) => {
          return (
            <>
              <div className="flex bg-gradient-to-b dark:from-pgray-800 from-pgray-200 dark:to-pgray-800 relative" id="howtouse">
                <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                  <div className="z-20 relative">

                    <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                      <div className="mr-5 sm:mx-10 w-5 relative">
                        <div className="h-full flex flex-col relative">
                          <div className="bg-pgray-300 dark:bg-pgray-500 w-1 h-20 mx-auto rounded-b-xl"></div>
                          <div className="bg-pgray-300 dark:bg-pgray-500 w-1 h-16 mx-auto rounded-b-xl"></div>
                          <div className="text-pblue-500 dark:text-pgray-300 relative my-10 flex-none">
                            <div className="h-6 w-6 animate-pulse" dangerouslySetInnerHTML={{ __html: home2023.data.attributes.HowToUse.Icon }}></div>
                            <div className="absolute top-0 left-0 w-6 h-6 rounded-full bg-pblue-500 dark:bg-pgray-400 blur-lg"></div>
                          </div>
                          <div className="pt-20 bg-pgray-300 dark:bg-pgray-500 w-1 mx-auto rounded-t-xl grow"></div>
                        </div>
                      </div>

                      <div className="grow sm:text-center lg:text-left mt-20 mr-24">
                        <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600/60 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 mb-2" >{home2023.data.attributes.HowToUse.PreTitle}</p>
                        <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-6xl sm:text-8xl">
                          <span className="">{home2023.data.attributes.HowToUse.Title}</span>
                        </h1>
                        <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 text-pgray-600 dark:text-pwhite/70 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 ">{home2023.data.attributes.HowToUse.SubTitle}</p>
                      </div>

                    </main>
                  </div>
                </div>
              </div>

              <div className="flex dark:bg-pgray-800 bg-pwhite relative">
                <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                  <div className="z-20 relative">
                    <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                      <div className="xs:mr-5 sm:mx-10 w-5 relative">
                        <div className="h-full flex flex-col relative">
                          <div className="h-full ml-[7px] text-pgray-300 dark:text-pgray-500 -mt-[1px] flex flex-col">
                            <div className="w-[70px] h-[128px] -scale-x-100 flex">
                              <svg className="mx-right" width="100%" height="auto" viewBox="0 0 68 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g stroke="currentColor" strokeWidth="4">
                                    <path d="M66, 0 C66, 23 55, 44 34, 66 C12, 87 2, 108 2, 130"></path>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="w-[70px] grow flex">
                              <div className="py-20 bg-pgray-300 dark:bg-pgray-500 w-1 ml-auto mr-[1px]"></div>
                            </div>

                            <div className="w-[70px] h-[128px] origin-center flex">
                              <svg className="mx-right" width="100%" height="auto" viewBox="0 0 68 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g stroke="currentColor" strokeWidth="4">
                                    <path d="M66, 0 C66, 23 55, 44 34, 66 C12, 87 2, 108 2, 130"></path>
                                  </g>
                                </g>
                              </svg>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="max-w-5xl mx-auto xs:px-4 sm:pr-6 lg:pr-6 relative z-20 mt-20 w-full">
                        <div className="xs:ml-4 mr-5 sm:mr-28 grid grid-cols-1 lg:grid-cols-2 gap-8 relative">

                          {home2023.data.attributes.HowToUse.Block.map((item, i) => (
                            <div key={i} className="bg-pgray-800 border border-pgray-200 dark:border-pgray-600 rounded-xl overflow-hidden relative h-[400px] flex">
                              <HoverShinyEffect className="z-[13]" />
                              <div className="w-full px-5 py-8 flex flex-row mt-auto relative">
                                <div className="h-6 w-6 my-1 mr-2 text-pgray-300 flex-none z-[11]" dangerouslySetInnerHTML={{ __html: item.Text3 }}></div>
                                <div className="flex flex-col shrink w-full bottom-0 relative">
                                  <div className="text-2xl font-medium text-pgray-800 dark:text-pwhite mb-2 z-[1]">{item.Title}</div>
                                  <div className="text-xl text-pgray-700/70 dark:text-pwhite/70 mb-4 z-[1]">{item.Text}</div>
                                  <div className="flex items-center">
                                  {!item.ButtonDisable && 
                                    <a href={item.ButtonURL} className="z-[30] relative transition-colors ease-in-out bg-pgray-100 hover:bg-pgray-200 dark:bg-pgray-500/50 hover:dark:bg-pgray-500 rounded-full h-9 flex">
                                      <div className="relative pl-3 pr-1.5 my-auto flex flex-row items-center justify-center">
                                        <div className="font-base text-pgray-800 dark:text-pwhite mr-3">{item.ButtonText}</div>
                                        <div className="text-pgray-900 dark:text-pwhite rounded-full w-8 h-6 flex items-center justify-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                          </svg>
                                        </div>
                                      </div>
                                    </a>
                                  }
                                  </div>
                                </div>
                              </div>
                              {/* <div className='absolute top-0 left-0 w-full lg:w-full bg-gradient-to-t from-pgray-900 h-full z-[11] pointer-events-none'></div> */}
                              <div className="absolute top-0 left-0 bg-cover bg-top h-full w-full" style={{ backgroundImage: mode !== "dark" ? `url(${process.env.REACT_APP_BACKEND_URL + item.LightImage.data[0].attributes.url})` : `url(${process.env.REACT_APP_BACKEND_URL + item.DarkImage.data.attributes.url})` }}></div>
                            </div>

                          ))}

                        </div>
                      </div>
                    </main>
                  </div>

                </div>
              </div>

              <div className="flex dark:bg-pgray-800 bg-pwhite relative">
                <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                  <div className="z-20 relative">
                    <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                      <div className="mr-5 sm:mx-10 w-5 relative">
                        <div className="h-full flex flex-col relative">
                          <div className="pb-20 bg-pgray-300 dark:bg-pgray-500 w-1 mx-auto grow"></div>
                        </div>
                      </div>
                    </main>
                  </div>

                </div>
                <div className='absolute right-36 -inset-y-96 w-[30rem] h-[30rem] blur-3xl bg-gradient-to-r from-pgreen-400 to-pblue-400 dark:from-pgreen-500 dark:to-pblue-500 z-9 rounded-full'></div>
              </div>
            </>
          );
        }}
      </Query>
    </>

  );
}

export default HomePageBenefitsComp;