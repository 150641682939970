import { lazy, Suspense } from "react";

// import MediumComp from '../comp/MediumComp';

const PrivacyPolicyPageMetaComp = (lazy(() => (import('../comp/PrivacyPolicyPageMetaComp'))));
// const DataLakesPageFeaturesComp = (lazy(() => (import('../comp/DataLakesPageFeaturesComp'))));
const PrivacyPolicyPageComp1 = (lazy(() => (import('../comp/PrivacyPolicyPageComp1'))));
// const PricingPageFAQComp = (lazy(() => (import('../comp/PricingPageFAQComp'))));
// const PricingPageCTABlockComp = (lazy(() => (import('../comp/PricingPageCTABlockComp'))));

function PrivacyPolicyPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Suspense fallback={<div></div>}><PrivacyPolicyPageMetaComp mode={mode} /></Suspense>
      {/* <Suspense fallback={<div></div>}><DataLakesPageFeaturesComp mode={mode} /></Suspense> */}
      <Suspense fallback={<div></div>}><PrivacyPolicyPageComp1 mode={mode} /></Suspense>
      
    </>
  );
}

export default PrivacyPolicyPage;