import React, { useRef, useState, useEffect } from "react"
import { useScramble } from "use-scramble"

export const HeaderDyslexiaEffectComp = (props) => {
  const [index, setIndex] = useState(0)
  const [texts, setTexts] = useState(props.strings);
  // useEffect(() => {
  //   setTexts(oldArray => [...oldArray, '[REDACTED]']);
  // }, []);

  const loopRef = useRef()
  
  const { ref, replay } = useScramble({
    text: texts[index],
    speed: 0.4,
    tick: 2,
    step: 5,
    scramble: 5,
    seed: 2,
    chance: 1,
    overdrive: false,
    overflow: true,
    onAnimationStart: () => {
      clearInterval(loopRef.current)
    },
    onAnimationEnd: () => {
      clearInterval(loopRef.current)
      loopRef.current = setTimeout(() => {
        setIndex((index) => (index < texts.length - 1 ? index + 1 : 0))
      }, 3500)
    },
  })

  return <span aria-label={texts[index]} ref={ref} onClick={replay} />
}
export default HeaderDyslexiaEffectComp;