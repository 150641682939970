import React, { Component } from "react";
import axios from "axios";
import clsx from 'clsx';
import Moment from 'react-moment';
import gql from "graphql-tag";
import Query from "../utils/Query";

const MEDIUMNEWS_QUERY = gql`
  query MediumNewsBlock {
    mediumNewsBlock {
      data {
        attributes {
          Title,
          SubTitle
        }
      }
    }
  }
`;

function getFirstGroup(regexp, str) {
  return Array.from(str.matchAll(regexp), m => m[1]);
}

class MediumBlogPostListComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
  }

  componentDidMount() {
    axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@parsiq").then(posts => {
      // console.log(posts.data);
      const dataarr = [];
      var reg2;
      const regexxx = /<\s*img[^>]*src=[\"|\'](.*?)[\"|\'][^>]*\/*>/g;

      posts.data.items.map((post, i) => (
        i < 6 && (
          reg2 = getFirstGroup(regexxx, post.description),
          dataarr.push([post.title, post.guid, post.pubDate, post.thumbnail, reg2[0]])
        )
      ))
      this.setState({
        posts: dataarr
      });
      // console.log(dataarr);
    });
  }

  render() {

    return (
      <Query query={MEDIUMNEWS_QUERY} id={null}>
        {({ data: { mediumNewsBlock } }) => {
          return (
            this.state.posts != [] && <div className="">
              <div className="flex dark:bg-pgray-900 bg-pwhite relative" id="spotlight">
                <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                  <div className="z-20 relative">
                    <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                      {/* <div className="mr-5 sm:mx-10 w-5 relative">
                        <div className="h-full flex flex-col relative">
                          <div className="bg-gradient-to-t from-pgray-300 to-pgray-300 dark:from-pgray-500 dark:to-pgray-500 h-[3rem] mx-auto rounded-b-xl"></div>
                          <div className="text-pred-500 dark:text-pgray-300 relative my-10 flex-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 animate-pulse" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                            </svg>
                            <div className="absolute top-0 left-0 w-6 h-6 rounded-full bg-pred-500 dark:bg-pgray-400 blur-lg"></div>
                          </div>
                          <div className="h-[2rem] bg-pgray-300 dark:bg-pgray-500 mx-auto rounded-t-xl grow"></div>
                          <div className="pb-16 bg-gradient-to-b from-pgray-300 dark:from-pgray-500 mx-auto rounded-t-xl grow"></div>
                        </div>
                      </div> */}
                      <div className="grow lg:text-left mt-20 mb-20">
                        <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-5xl">
                          <div className="font-medium" dangerouslySetInnerHTML={{ __html: mediumNewsBlock.data.attributes.Title }}></div>
                        </h1>
                        <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0 " dangerouslySetInnerHTML={{ __html: mediumNewsBlock.data.attributes.SubTitle }}></p>
                      </div>
                    </main>
                  </div>
                </div>
              </div>

              <div className="relative bg-pwhite dark:bg-pgray-900">
                <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-6 relative z-20">
                  <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-x-8 gap-y-8 ">
                    {this.state.posts && this.state.posts.map((post, i) => (
                      <a href={post[1]} key={i} className="group dark:bg-pgray-800 bg-pgray-50 dark:hover:bg-pgray-700 hover:bg-pgray-100 rounded-xl">
                        <div className="h-64 bg-cover bg-center overflow-hidden rounded-xl" style={{ backgroundImage: `url(${post[4]})` }}>
                          <div className={clsx("opacity-0 hover:opacity-100 h-full p-4 bg-gradient-to-b from-pgray-900/70 to-transparent group-hover:backdrop-blur-lg", { "opacity-100": post[4] == "" })}>
                            {post[0] &&
                              <div className="">
                                <div className="">
                                  <div className="font-bold text-pwhite ">
                                    <span dangerouslySetInnerHTML={{ __html: post[0] }} />
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="mt-1 text-pwhite group-hover:opacity-100 opacity-90">
                              <Moment format="MMMM D, YYYY">
                                {post[2]}
                              </Moment>
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default MediumBlogPostListComp;
