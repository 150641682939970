import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import Query from "../utils/Query";
import clsx from 'clsx';
import { useQuery } from "@apollo/client";

import DropdownComp from "../comp/DropdownComp";
import MegamenuComp from "../utils/MegamenuComp";
import Accordion from './Navbar2023AccordionComp';

const NAVBAR2023_QUERY = gql`
  query Navbar2023Comp {
    navbar2023 {
      data {
        attributes {
          Products {
            Title
            SubTitle
            Link {
              Disable
              External
              Title
              Tag
              SubTitle
              URL
              Icon
              IconColor
            }
            Title2
            Links2 {
              Disable
              External
              Title
              URL
              Icon
              IconColor
            }
          }
          Pricing {
            Disable
            External
            Title
            URL
          }
          UseCases {
            Title
            Link {
              Disable
              External
              Title
              URL
              Icon
              IconColor
            }
            Title2
            Links2 {
              Disable
              External
              Title
              URL
              LightImage {
                data {
                  attributes {
                    url
                  }
                }
              },
              DarkImage {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          Community {
            Disable
            External
            Title
            URL
          }
          Company {
            Disable
            External
            Title
            URL
          }
        }
      }
    }
  }
`;

function MobileNavbarLinkComp(props) {
  return (
    !props.disable && (
      <>
        {props.external === false ? (
          <Link reloadDocument to={props.url} className={clsx("flex items-center justify-between p-4 text-left rounded-2xl", props.bgcolor)} >
              <p className="font-medium dark:text-pwhite text-pgray-800">{props.title}</p>
          </Link>
        ) : (
          <a href={props.url} className={clsx("flex items-center justify-between p-4 text-left rounded-2xl", props.bgcolor)}>
              <p className="font-medium dark:text-pwhite text-pgray-800">{props.title}</p>
          </a>
        )}
      </>
    )
  );
}

function LinkComp(props) {
  return (
    !props.disable && (
      <>
        {props.external === false ? (
          <Link reloadDocument to={props.url}>
            <li className={props.className}>{props.title}</li>
          </Link>
        ) : (
          <a href={props.url} target="_blank" rel="noreferrer">
            <li className={props.className}>{props.title}</li>
          </a>
        )}
      </>
    )
  );
}

function LinkWithIconComp(props) {
  return (
    !props.disable && (
      <>
        {props.external === false ? (
          <Link reloadDocument to={props.url}>
            <li className={props.className}>
              <div className={clsx("flex items-center gap-2")}>
                {props.children}
                {props.title}
              </div>
            </li>
          </Link>
        ) : (
          <a href={props.url} target="_blank" rel="noreferrer">
            <li className={props.className}>
              <div className={clsx("flex items-center gap-2")}>
                {props.children}
                {props.title}
              </div>
            </li>
          </a>
        )}
      </>
    )
  );
}

function LinkWithImageComp(props) {
  return (
    !props.disable && (
      <>
        {props.external === false ? (
          <Link reloadDocument to={props.url}>
            <li className={props.className}>
              <div className={clsx("flex items-center gap-2")}>
                <img className="" src={props.image} />
              </div>
            </li>
          </Link>
        ) : (
          <a href={props.url} target="_blank" rel="noreferrer">
            <li className={props.className}>
              <div className={clsx("flex items-center gap-2")}>
                <img className="" src={props.image} />
              </div>
            </li>
          </a>
        )}
      </>
    )
  );
}


function BigLinkComp(props) {
  return (
    !props.disable && (
      <>
        {props.external === false ? (
          <Link reloadDocument to={props.url}>
            <li className={props.className + " flex flex-row gap-4"}>
              {props.children &&
                <div className={clsx("w-12 h-12 rounded-xl flex items-center justify-center bg-opacity-10", props.iconcolor)}>
                  {props.children}
                </div>
              }
              <div className="flex flex-col my-auto">
                {props.title}
                <span className="text-sm font-normal text-pgray-600/70 dark:text-pwhite/70">{props.subtitle}</span>
              </div>
            </li>
          </Link>
        ) : (
          <a href={props.url} target="_blank" rel="noreferrer">
            <li className={props.className + " flex flex-row gap-4"}>
              {props.children &&
                <div className={clsx("w-12 h-12 rounded-xl flex items-center justify-center bg-opacity-10 opacity-50", props.iconcolor)}>
                  {props.children}
                </div>
              }
              <div className="flex flex-col my-auto">
                {props.title}
                <span className="text-sm font-normal text-pgray-600/70 dark:text-pwhite/70">{props.subtitle}</span>
              </div>
            </li>
          </a>
        )}
      </>
    )
  );
}

function BigLink2Comp(props) {
  return (

    !props.disable ? (
      <>
        {props.external === false ? (
          <Link reloadDocument to={props.url}>
            <li className={props.className + " flex flex-row gap-3"}>
              {props.children &&
                <div className={clsx("w-8 h-8 rounded-xl flex-none flex items-center justify-center bg-opacity-10 dark:bg-opacity-30", props.iconcolor)}>
                  {props.children}
                </div>
              }
              <div className="flex flex-col mt-1">
                <div className="mb-1 flex flex-row mb-3 space-x-3">
                  <div className="text-pgray-700 dark:text-pwhite">
                    {props.title}
                  </div>
                  {props.tag &&
                    <div class="font-regular text-xs sm:text-xs text-porange-500 leading-tight px-2 py-1 border border-porange-500 rounded-full">{props.tag}</div>
                  }
                </div>
                <span className="text-sm font-normal text-pgray-600 dark:text-pwhite/70">{props.subtitle}</span>
              </div>
            </li>
          </Link>
        ) : (
          <a href={props.url} target="_blank" rel="noreferrer">
            <li className={props.className + " flex flex-row gap-3"}>
              {props.children &&
                <div className={clsx("w-8 h-8 rounded-xl flex-none flex items-center justify-center bg-opacity-10 dark:bg-opacity-30", props.iconcolor)}>
                  {props.children}
                </div>
              }
              <div className="flex flex-col mt-1">
                <div className="mb-1 flex flex-row mb-3 space-x-3">
                  <div className="text-pgray-700 dark:text-pwhite">
                    {props.title}
                  </div>
                  {props.tag &&
                    <div class="font-regular text-xs sm:text-xs text-porange-500 leading-tight px-2 py-1 border border-porange-500 rounded-full">{props.tag}</div>
                  }
                </div>
                <span className="text-sm font-normal text-pgray-600 dark:text-pwhite/70">{props.subtitle}</span>
              </div>
            </li>
          </a>
        )}
      </>
    ) : (
      <>
        {
          <li className={props.className + " flex flex-row gap-3"}>
            {props.children &&
              <div className={clsx("w-8 h-8 rounded-xl flex-none flex items-center justify-center bg-opacity-10 dark:bg-opacity-30 opacity-50", props.iconcolor)}>
                {props.children}
              </div>
            }
            <div className="flex flex-col mt-1">
              <div className="mb-1 flex flex-row mb-3 space-x-3">
                <div className="text-pgray-700 dark:text-pwhite">
                  {props.title}
                </div>
                {props.tag &&
                  <div class="font-regular text-xs sm:text-xs text-porange-500 leading-tight px-2 py-1 border border-porange-500 rounded-full">{props.tag}</div>
                }
              </div>
              <span className="text-sm font-normal text-pgray-600 dark:text-pwhite/50">{props.subtitle}</span>
            </div>
          </li>
        }
      </>
    )
  );
}

function NavbarComp(props) {
  const mode = props.mode ? props.mode : "light";
  const onError = useCallback(() => console.log("Error callback fired!"), []);
  const { data } = useQuery(NAVBAR2023_QUERY, { onError });
  // let location = useLocation();
  const [mobileNavbar, setMobileNavbar] = useState(false)

  return (
    <Query query={NAVBAR2023_QUERY} id={null}>
      {({ data: { navbar2023 } }) => {
        return (
          <div className={clsx("fixed t-0 w-full z-30 backdrop-blur-xl group transition-colors", { "bg-pwhite dark:bg-pgray-900 dark:hover:bg-pgray-900": mobileNavbar })}>
            <div className={clsx("max-h-[calc(100vh-64px)] overflow-scroll top-[72px] absolute bg-pwhite dark:bg-pgray-900 w-full md:hidden pb-4 rounded-b-2xl", { "hidden": !mobileNavbar })} >
              <div className="mx-4 space-y-2">
                <MobileNavbarLinkComp
                  title="Home"
                  url={'/'}
                  external={false}
                  bgcolor="dark:bg-pgray-800 bg-pgray-50"
                />
                <Accordion
                  title="Products"
                >
                  {navbar2023.data.attributes.Products.Link.map((item, i) => (
                    !item.Disable &&
                      <MobileNavbarLinkComp
                        key={i}
                        title={item.Title}
                        url={item.URL}
                        external={item.External}
                        bgcolor="dark:bg-pgray-900 bg-pwhite"
                      />
                  ))}
                </Accordion>
                <MobileNavbarLinkComp
                  title="Pricing"
                  url={'/pricing'}
                  external={false}
                  bgcolor="dark:bg-pgray-800 bg-pgray-50"
                />
                <Accordion
                  title="Use Cases"
                >
                  {navbar2023.data.attributes.UseCases.Link.map((item, i) => (
                    !item.Disable &&
                      <MobileNavbarLinkComp
                        key={i}
                        title={item.Title}
                        url={item.URL}
                        external={item.External}
                        bgcolor="dark:bg-pgray-900 bg-pwhite"
                      />
                  ))}
                </Accordion>
                <MobileNavbarLinkComp
                  title="Community"
                  url={'/community'}
                  external={false}
                  bgcolor="dark:bg-pgray-800 bg-pgray-50"
                />
                <Accordion
                  title="Company"
                >
                  {navbar2023.data.attributes.Company.map((item, i) => (
                    !item.Disable &&
                      <MobileNavbarLinkComp
                        key={i}
                        title={item.Title}
                        url={item.URL}
                        external={item.External}
                        bgcolor="dark:bg-pgray-900 bg-pwhite"
                      />
                  ))}
                </Accordion>
              </div>

            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <div className="flex items-center py-4 md:space-x-10">
                {/* {console.log(location)} */}
                <a
                  className="flex justify-center"
                  href="https://parsiq.net"
                >
                  <svg
                    className="my-auto hidden lg:block h-7 w-auto sm:h-7 dark:text-pwhite text-pgray-900"
                    width="201"
                    height="48"
                    viewBox="0 0 201 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6303 32.2124V37.3371H15.7655V36.0559H22.8265V47.3119C10.9971 46.6713 1.36847 36.9711 0.726562 25.166H12.0058V32.1209L10.6303 32.2124Z"
                      fill="currentColor"
                    />
                    <path
                      d="M32.4555 36.0557V37.3369H37.5908V32.2122H36.3069V25.2573H47.5862C46.9443 37.0624 37.224 46.6711 25.3945 47.3117V36.0557H32.4555Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.2432 8.87646C67.4835 8.87646 64.1823 10.2492 61.8897 12.8115V9.42554H57.3047V45.5728H61.8897V32.4866C64.274 34.9574 67.5752 36.4216 71.2432 36.4216C78.9461 36.4216 84.9067 30.3818 84.9067 22.6948C84.9067 14.9163 78.9461 8.87646 71.2432 8.87646ZM71.1515 13.3606C76.2868 13.3606 80.4133 17.3871 80.4133 22.6033C80.4133 27.8194 76.3785 31.846 71.1515 31.846C66.0163 31.846 61.8897 27.8194 61.8897 22.6033C61.8897 17.3871 65.9246 13.3606 71.1515 13.3606Z"
                      fill="currentColor"
                    />
                    <path
                      d="M111.134 12.72C108.75 10.2492 105.449 8.87646 101.781 8.87646C94.0778 8.87646 88.1172 14.9163 88.1172 22.6033C88.1172 30.2903 94.1695 36.33 101.781 36.33C105.449 36.33 108.75 34.9574 111.134 32.4866V35.781H115.811V9.33402H111.134V12.72ZM101.964 31.846C96.8288 31.846 92.7022 27.8194 92.7022 22.6033C92.7022 17.3871 96.7371 13.3606 101.964 13.3606C107.099 13.3606 111.134 17.3871 111.134 22.6033C111.134 27.8194 107.099 31.846 101.964 31.846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M134.977 9.05945H133.877C130.942 9.05945 128.558 9.97456 126.816 11.7133V9.42549H122.23V35.8724H126.816V21.322C126.816 14.1841 132.226 13.635 133.877 13.635H134.977V9.05945Z"
                      fill="currentColor"
                    />
                    <path
                      d="M149.008 20.4985C145.431 19.5834 143.322 18.9428 143.322 16.838C143.322 14.4587 145.706 13.3606 148.091 13.3606C150.567 13.3606 152.584 14.4587 153.318 16.3805L153.776 17.5701L157.719 15.2823L157.352 14.3672C155.794 10.8897 152.401 8.87646 148.091 8.87646C142.68 8.87646 138.737 12.2624 138.737 16.838C138.737 22.3287 143.689 23.6099 147.724 24.708C151.3 25.6232 153.501 26.3553 153.501 28.46C153.501 31.0224 150.842 31.9375 148.274 31.9375C145.156 31.9375 142.864 30.6563 142.13 28.3685L141.672 27.0874L137.637 29.4667L137.912 30.2903C139.379 34.1338 143.139 36.4216 148.182 36.4216C153.96 36.4216 157.994 33.1272 157.994 28.46C157.994 22.7863 152.951 21.5051 149.008 20.4985Z"
                      fill="currentColor"
                    />
                    <path
                      d="M168.171 9.42578H163.586V35.8727H168.171V9.42578Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.142 9.42554V12.8115C193.758 10.3407 190.456 8.87646 186.788 8.87646C179.086 8.87646 173.125 14.9163 173.125 22.6033C173.125 30.2903 179.177 36.3301 186.788 36.3301C190.548 36.3301 193.849 34.9574 196.142 32.395V45.4812H200.727V9.33402H196.142V9.42554ZM186.88 31.846C181.745 31.846 177.618 27.8194 177.618 22.6033C177.618 17.3871 181.653 13.3606 186.88 13.3606C192.107 13.3606 196.142 17.3871 196.142 22.6033C196.142 27.8194 192.107 31.846 186.88 31.846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M169.091 0H162.672V6.40584H169.091V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M22.8265 11.9882H32.5468V10.4324H37.6821V16.1062H32.5468V14.5505H22.8265V25.1659L33.8306 25.2574V32.2123H32.5468V33.4935H15.7655V32.2123H14.5734V15.5571H15.8572V10.4324H10.722V15.5571H12.0058V22.512H0.726562C1.36847 10.707 11.0888 1.09823 22.8265 0.457642V11.9882Z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="my-auto lg:hidden h-7 w-auto sm:h-8 dark:text-pwhite text-pgray-900"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M10.63 32.212v5.125h5.135v-1.281h7.061v11.256c-11.829-.64-21.458-10.34-22.1-22.146h11.28v6.955l-1.376.091zM32.456 36.056v1.28h5.135v-5.124h-1.284v-6.955h11.28c-.643 11.805-10.363 21.414-22.193 22.055V36.056h7.062z"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M22.826 11.988h9.72v-1.556h5.136v5.674h-5.135V14.55h-9.72v10.616l11.004.091v6.955h-1.284v1.281H15.766v-1.28h-1.193V15.556h1.284v-5.125h-5.135v5.125h1.284v6.955H.726C1.369 10.707 11.09 1.098 22.827.458v11.53z"
                    ></path>
                  </svg>
                  {props.location !== "" && (
                    <div className="border-r border-pgray-300 mx-4" />
                  )}
                  <div className="flex">
                    <div className="text-2xl font-display font-bold dark:text-pwhite text-pgray-900 opacity-60">
                      {props.location}
                    </div>
                  </div>
                </a>

                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-6">
                  {/* <Link reloadDocument to="/community"> */}
                  <MegamenuComp
                    titleText="Products"
                    titleClassName="w-full whitespace-nowrap inline-flex items-center justify-center px-3 py-1 border border-transparent rounded-lg text-base font-medium text-pgray-800 dark:text-pwhite group-hover:bg-pwhite/0 group-hover:dark:bg-transparent backdrop-blur-xl dark:bg-pgray-900/10 group-hover:backdrop-blur-none hover:!bg-pwhite/30 dark:hover:bg-pgray-800 cursor-pointer"
                    titleHoverClassName="bg-pgray-800"
                  >
                    <div className="w-full bg-pgray-50 dark:bg-pgray-800 rounded-lg p-3 flex flex-col shadow-lg highlight">
                      <div className="text-gray-700 dark:text-pwhite font-medium w-full grid grid-cols-1 gap-y-4">
                        <ul className="w-full col-span-2">
                          <li className="text-pgray-200 font-medium ml-3 mb-3 mt-2">
                            <div className="text-pgray-500 dark:text-pgray-200">{navbar2023.data.attributes.Products.Title}</div>
                            <div className="text-sm font-normal text-pgray-600/70 dark:text-pwhite/70">{navbar2023.data.attributes.Products.SubTitle}</div>
                          </li>
                          <hr class="h-px my-4 bg-pgray-200 border-0 dark:bg-pgray-700 mx-3"></hr>
                          <div className="w-full grid grid-cols-2 gap-2">
                            {navbar2023.data.attributes.Products.Link.map((item, i) => (
                              // !item.Disable &&
                              <BigLink2Comp
                                key={i}
                                disable={item.Disable}
                                external={item.External}
                                iconcolor={item.IconColor}
                                title={item.Title}
                                tag={item.Tag}
                                subtitle={item.SubTitle}
                                url={item.URL}
                                className={clsx("py-2 px-3 transition-colors w-full h-full rounded-lg ", { "hover:bg-pgray-100 dark:hover:bg-pgray-700 cursor-pointer": !item.Disable })}
                              >
                                {item.Icon && <div className="h-6 w-6" dangerouslySetInnerHTML={{ __html: item.Icon }}></div>}
                              </BigLink2Comp>
                            ))}
                          </div>

                        </ul>
                        <ul className="w-full p-4 rounded-lg bg-pgray-100 dark:bg-pgray-900/90">
                          <li className="text-pgray-500 font-medium mb-3 flex flex-row items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-1 flex-none h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                            </svg>
                            Docs
                          </li>
                          <div className="grid grid-cols-3 gap-2">
                            {navbar2023.data.attributes.Products.Links2.map((item, i) => (
                              !item.Disable &&
                              <LinkComp
                                key={i}
                                disable={item.Disable}
                                external={item.External}
                                title={item.Title}
                                subtitle={item.SubTitle}
                                url={item.URL}
                                className="w-full rounded cursor-pointer underline font-normal hover:font-medium"
                              />
                            ))}
                          </div>
                          {/* <li className="text-sm font-normal text-pgray-600/70 dark:text-pwhite/70">{navbar2023.data.attributes.Products.SubTitle}</li> */}
                        </ul>
                      </div>
                    </div>
                  </MegamenuComp>
                  {/* </Link> */}

                  <LinkComp
                    disable={false}
                    external={false}
                    title="Pricing"
                    url="/pricing"
                    className="whitespace-nowrap inline-flex items-center justify-center px-3 py-1 border border-transparent rounded-lg text-base font-medium text-pgray-800 dark:text-pwhite group-hover:bg-pwhite/0 group-hover:dark:bg-transparent backdrop-blur-xl dark:bg-pgray-900/10 group-hover:backdrop-blur-none hover:!bg-pwhite/30 dark:hover:bg-pgray-800 cursor-pointer"
                  />

                  <DropdownComp
                    titleText="Use Cases"
                    titleClassName="whitespace-nowrap inline-flex items-center justify-center px-3 py-1 border border-transparent rounded-lg text-base font-medium text-pgray-800 dark:text-pwhite group-hover:bg-pwhite/0 group-hover:dark:bg-transparent backdrop-blur-xl dark:bg-pgray-900/10 group-hover:backdrop-blur-none hover:!bg-pwhite/30 dark:hover:bg-pgray-800 cursor-pointer"
                    titleHoverClassName="bg-pgray-800"
                  >
                    <div className={clsx("bg-pgray-50 dark:bg-pgray-800 rounded-lg p-3 flex flex-col shadow-lg highlight list-none text-gray-700 dark:text-pwhite font-medium grid gap-4 w-[16rem]", { "grid-cols-2 w-[32rem]": navbar2023.data.attributes.UseCases.Links2.length > 0 })}>
                      <div>
                        <li className="text-pgray-500 font-medium ml-3 mb-3 flex-inline">
                          {navbar2023.data.attributes.UseCases.Title}
                        </li>
                        {navbar2023.data.attributes.UseCases.Link.map((item, i) => (
                          !item.Disable &&
                          <LinkWithIconComp
                            key={i}
                            disable={item.Disable}
                            external={item.External}
                            title={item.Title}
                            subtitle={item.SubTitle}
                            url={item.URL}
                            className="py-2 px-3 hover:bg-pgray-100 dark:hover:bg-pgray-700 w-full rounded cursor-pointer"
                          >
                            {item.Icon && <div className="h-6 w-6" dangerouslySetInnerHTML={{ __html: item.Icon }}></div>}
                          </LinkWithIconComp>
                        ))}
                      </div>
                      {navbar2023.data.attributes.UseCases.Links2.length > 0 &&
                        <div>
                          <li className="text-pgray-500 font-medium ml-3 mb-3 flex-inline">
                            {navbar2023.data.attributes.UseCases.Title2}
                          </li>
                          {navbar2023.data.attributes.UseCases.Links2.map((item, i) => (
                            !item.Disable &&
                              <LinkWithIconComp
                              key={i}
                              disable={item.Disable}
                              external={item.External}
                              title={item.Title}
                              subtitle={item.SubTitle}
                              url={item.URL}
                              className="py-2 px-3 hover:bg-pgray-100 dark:hover:bg-pgray-700 w-full rounded cursor-pointer"
                            >
                              {/* {item.Icon && <div className="h-6 w-6" dangerouslySetInnerHTML={{ __html: item.Icon }}></div>} */}
                            </LinkWithIconComp>
                            // <LinkWithImageComp
                            //   key={i}
                            //   disable={item.Disable}
                            //   external={item.External}
                            //   title={item.Title}
                            //   image={mode !== "dark" ? (process.env.REACT_APP_BACKEND_URL + item.LightImage.data.attributes.url) : (process.env.REACT_APP_BACKEND_URL + item.DarkImage.data.attributes.url)}
                            //   url={item.URL}
                            //   className="py-2 px-3 hover:bg-pgray-100 dark:hover:bg-pgray-700 w-full rounded cursor-pointer"
                            // />
                          ))}
                        </div>
                      }
                    </div>
                  </DropdownComp>

                  {navbar2023.data.attributes.Community && (
                    <Link reloadDocument to="/community">
                      <DropdownComp
                        titleText="Community"
                        titleClassName="whitespace-nowrap inline-flex items-center justify-center px-3 py-1 border border-transparent rounded-lg text-base font-medium text-pgray-800 dark:text-pwhite group-hover:bg-pwhite/0 group-hover:dark:bg-transparent backdrop-blur-xl dark:bg-pgray-900/10 group-hover:backdrop-blur-none hover:!bg-pwhite/30 dark:hover:bg-pgray-800 cursor-pointer"
                        titleHoverClassName="bg-pgray-800"
                      >
                        <div className="bg-pgray-50 dark:bg-pgray-800 rounded-lg p-3 flex flex-col shadow-lg highlight w-48">
                          <div className="text-gray-700 dark:text-pwhite font-medium w-full">
                            <ul className="w-full">
                              {navbar2023.data.attributes.Community.map(
                                (item, i) => (
                                  <LinkComp
                                    key={i}
                                    disable={item.Disable}
                                    external={item.External}
                                    title={item.Title}
                                    url={item.URL}
                                    className="py-2 px-3 hover:bg-pgray-100 dark:hover:bg-pgray-700 w-full rounded cursor-pointer"
                                  />
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </DropdownComp>
                    </Link>
                  )}

                  {navbar2023.data.attributes.Company && (
                    <DropdownComp
                      titleText="Company"
                      titleClassName="whitespace-nowrap inline-flex items-center justify-center px-3 py-1 border border-transparent rounded-lg text-base font-medium text-pgray-800 dark:text-pwhite group-hover:bg-pwhite/0 group-hover:dark:bg-transparent backdrop-blur-xl dark:bg-pgray-900/10 group-hover:backdrop-blur-none hover:!bg-pwhite/30 dark:hover:bg-pgray-800 cursor-pointer"
                      titleHoverClassName="bg-pgray-800"
                    >
                      <div className="bg-pgray-50 dark:bg-pgray-800 rounded-lg p-3 flex flex-col shadow-lg highlight w-48">
                        <div className="text-gray-700 dark:text-pwhite font-medium w-full">
                          <ul className="w-full">
                            {navbar2023.data.attributes.Company.map((item, i) => (
                              <LinkComp
                                key={i}
                                disable={item.Disable}
                                external={item.External}
                                title={item.Title}
                                url={item.URL}
                                className="py-2 px-3 hover:bg-pgray-100 dark:hover:bg-pgray-700 w-full rounded cursor-pointer"
                              />
                            ))}
                          </ul>
                        </div>
                      </div>
                    </DropdownComp>
                  )}
                </div>
                <button type="button" class="ml-auto inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-pgray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 ring-pgray-300 dark:text-pgray-300 " onClick={() => setMobileNavbar(!mobileNavbar)} >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
}

export default NavbarComp;
