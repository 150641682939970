import React from "react";
import gql from "graphql-tag";
import clsx from 'clsx';
import Query from "../utils/Query";
import HoverShinyEffect from "../utils/HoverShinyEffect";
// import ScrollToHashElement from "../utils/ScrollToHashElement";
// import useDidMount from "../utils/useDidMount";

const HOMEPAGE2023_QUERY = gql`
  query HomePage {
    home2023 {
      data {
        attributes {
          Benefits {
            __typename,
            ... on ComponentBlockHomeBlockimage2023 {
              Icon,
              PreTitle,
              Title,
              SubTitle,
              Block{
                Icon,
                Tag,
                Title,
                Text,
                Text2,
                Text3,
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                ImageText,
                ButtonDisable,
                ButtonExternal,
                ButtonText,
                ButtonURL
              }
            }
          }
        }
      }
    }
  }
`;

function HomePageBenefitsComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <div className="flex bg-pgray-100 dark:bg-pgray-900 relative" id="benefits">
        <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
          <div className="z-20 relative">
          {/* {useDidMount(function() { */}
            {/* <ScrollToHashElement/> */}
          {/* })} */}
            
            <Query query={HOMEPAGE2023_QUERY} id={null}>
              {({ data: { home2023, i } }) => {
                return (
                  <main key={i} className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                    <div className="mr-5 sm:mx-10 w-5 relative">
                      <div className="h-full flex flex-col relative">
                        <div className="bg-pgray-300 dark:bg-pgray-500 w-1 h-20 mx-auto rounded-b-xl"></div>
                        <div className="text-pblue-500 dark:text-pgray-300 relative my-10 flex-none">
                          <div className="h-6 w-6 animate-pulse" dangerouslySetInnerHTML={{ __html: home2023.data.attributes.Benefits.Icon }}></div>
                          <div className="absolute top-0 left-0 w-6 h-6 rounded-full bg-pblue-400 dark:bg-pgray-400 blur-lg"></div>
                        </div>

                        <div className="pt-20 bg-pgray-300 dark:bg-pgray-500 w-1 mx-auto rounded-t-xl grow"></div>
                        <div className="pb-20 bg-gradient-to-b from-pgray-300 dark:from-pgray-500 w-1 mx-auto grow"></div>
                      </div>
                    </div>

                    <div className="grow lg:text-left mt-20">
                      <h1 className="relative tracking-tight dark:text-pwhite text-pgray-800 text-6xl sm:text-8xl">
                        <span className="">{home2023.data.attributes.Benefits.Title}</span>
                      </h1>
                      <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0 " dangerouslySetInnerHTML={{ __html: home2023.data.attributes.Benefits.SubTitle }}></p>
                    </div>
                  </main>
                );
              }}
            </Query>
          </div>

        </div>
      </div>
      <div className="bg-pgray-100 dark:bg-pgray-900 relative">
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-6 relative z-10">
          <Query query={HOMEPAGE2023_QUERY} id={null}>
            {({ data: { home2023, m } }) => {
              return (
                <div key={m} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                  {home2023.data.attributes.Benefits.Block.map((item, i) => (
                    <div key={i} className={clsx("dark:bg-pgray-800 border border-pgray-200 dark:border-pgray-600 rounded-xl h-[400px] md:h-[500px] overflow-hidden relative", { "sm:col-span-2": i == 0 }, { "sm:col-span-2": i == 3 })}>
                      <HoverShinyEffect className="z-[13]" />
                      <div className="text-center absolute w-full bottom-0 z-[12] p-10">
                        <div className="text-3xl font-medium text-pgray-800 dark:text-pwhite mb-2">{item.Title}</div>
                        <div className="text-xl font-medium text-pgray-600/70 dark:text-pwhite/70">{item.Text}</div>
                      </div>
                      {/* <div className='absolute top-0 left-0 w-full lg:w-full bg-gradient-to-t from-pgray-900 h-full z-[11]'></div> */}
                      <div className="bg-contain bg-no-repeat md:bg-cover bg-top md:bg-center h-full" style={{ backgroundImage: mode !== "dark" ? `url(${process.env.REACT_APP_BACKEND_URL + item.LightImage.data[0].attributes.url})` : `url(${process.env.REACT_APP_BACKEND_URL + item.DarkImage.data.attributes.url})` }}></div>
                    </div>
                  ))}
                </div>
              );
            }}
          </Query>
        </div>
        <div className='absolute bottom-16 left-0 w-full lg:w-full bg-gradient-to-t from-pgray-200 dark:from-pgray-800 h-192 z-0'></div>
        <div className='absolute bottom-0 left-0 w-full lg:w-full bg-pgray-200 dark:bg-pgray-800 h-16 z-0'></div>
      </div>

      <div className="flex bg-pgray-200 dark:bg-pgray-800 relative">
        <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
          <div className="z-20 relative">
            <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
              <div className="mr-5 sm:mx-10 w-5 relative">
                <div className="h-full flex flex-col relative">
                  <div className="bg-gradient-to-t from-pgray-300 dark:from-pgray-500 w-1 h-20 mx-auto"></div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>

  );
}

export default HomePageBenefitsComp;