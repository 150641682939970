import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import clsx from 'clsx';

function Dropdown(props) {
    // const [setActive, setActiveState] = useState("");
    const [isOverButton, setIsOverButton] = useState(false);
    const [isOverList, setIsOverList] = useState(false);
    const [isOpen, setIsOpen] = useState();
    const [isTouchInput, setIsTouchInput] = useState();
    const [hasClicked, setHasClicked] = useState();
    const button = useRef(null);
    const titleHoverClassName = props.titleHoverClassName

  useLayoutEffect(() => {
    if (isOpen && !isOverButton && !isOverList && !isTouchInput) {
      // button.current.click();
      setIsOpen(false);
    } else if (!isOpen && (isOverButton || isOverList) && !isTouchInput) {
      // button.current.click();
      setIsOpen(true);
    }
  }, [isOverButton, isOverList]);

  useEffect(() => {
    setIsTouchInput(false);
    setHasClicked(false);
  }, [hasClicked]);

    return (
        <div className="relative" ref={button}>
            <button className={clsx(`${props.titleClassName}`, {titleHoverClassName : isOpen})} onMouseEnter={event => setIsOverButton(true)} onMouseLeave={event => setIsOverButton(false)} onTouchStart={() => {setIsTouchInput(true)}} onClick={() => {setHasClicked(true); setIsOpen(!isOpen)}} onKeyDown={() => {setIsOpen(!isOpen)}}>
                <span className="dark:text-pwhite text-pgray-800">{props.titleText}</span>
            </button>
            <div className={clsx("absolute right-0", {"hidden" : !isOpen})} onMouseEnter={event => {setIsOverList(true)}} onMouseLeave={event => {setIsOverList(false)}}>
                <div className="mt-2">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Dropdown;