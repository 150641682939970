import { Suspense, useState } from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";
import { InView } from 'react-intersection-observer';

import HomeMediumComp from '../comp/HomeMediumComp';
import MetaComp from '../comp/MetaComp';
import HomeHeaderComp from '../comp/HomeEarly2023PageHeaderComp';
import HomeBenefitsComp from '../comp/HomeEarly2023PageBenefitsComp';
import HomeExtra1Comp from '../comp/HomeEarly2023PageExtra1Comp';
import HomeExtra2Comp from '../comp/HomeEarly2023PageExtra2Comp';
import HomeAPIComp from '../comp/HomeEarly2023PageAPIComp';
import HomeContactUsComp from '../comp/HomeEarly2023PageContactUsComp';
import HomeEarly2023SlidescrollerComp from '../comp/HomeEarly2023SlidescrollerComp';
// const MetaComp = (lazy(() => (import('../comp/MetaComp'))));
// const HomeHeaderComp = (lazy(() => (import('../comp/HomeEarly2023PageHeaderComp'))));
// const HomeBenefitsComp = (lazy(() => (import('../comp/HomeEarly2023PageBenefitsComp'))));
// const HomeExtra1Comp = (lazy(() => (import('../comp/HomeEarly2023PageExtra1Comp'))));
// const HomeExtra2Comp = (lazy(() => (import('../comp/HomeEarly2023PageExtra2Comp'))));
// const HomeAPIComp = (lazy(() => (import('../comp/HomeEarly2023PageAPIComp'))));
// const HomeContactUsComp = (lazy(() => (import('../comp/HomeEarly2023PageContactUsComp'))));


function HomePage(props) {
  const mode = props.mode ? props.mode : "light";

  const HOME2023METAPAGE_QUERY = gql`
    query Home2023Page {
      home2023 {
        data {
          attributes {
            Meta {
              Title
              Desc
              Img {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  // const { ref, inView } = useInView({
  //   threshold: 0
  // });

  const [benefits, setBenefits] = useState(false);
  const [extra1Comp, setExtra1Comp] = useState(false);
  const [extra2Comp, setExtra2Comp] = useState(false);
  const [apiComp, setApiComp] = useState(false);
  const [spotlightComp, setSpotlightComp] = useState(false);
  const [contactUsComp, setContactUsComp] = useState(false);

  return (
    <>
      <Query query={HOME2023METAPAGE_QUERY} id={null}>
        {({ data: { home2023 } }) => {
          return (
            <>
              <Suspense fallback={<div></div>}><MetaComp mode={mode} data={home2023.data.attributes.Meta} /></Suspense>
            </>
          );
        }}
      </Query>

      {/* <HomeHeaderComp mode={mode} />
      <HomeBenefitsComp mode={mode} />
      <HomeExtra1Comp mode={mode} />
      <HomeExtra2Comp mode={mode} /> 
      <HomeAPIComp mode={mode} />
      <HomeMediumComp mode={mode} />
      <HomeContactUsComp mode={mode} /> */}
      {/* <SuspenseList> */}
        <Suspense fallback={<div className=""></div>}><HomeHeaderComp mode={mode} /></Suspense>
      {/* </SuspenseList> */}


        <div className="relative">
          <HomeEarly2023SlidescrollerComp benefits={benefits} extra1Comp={extra1Comp} extra2Comp={extra2Comp} apiComp={apiComp} spotlightComp={spotlightComp} contactUsComp={contactUsComp} />
          <InView onChange={setBenefits}><Suspense fallback={<div></div>}><HomeBenefitsComp mode={mode} /></Suspense></InView>
          <InView onChange={setExtra1Comp}><Suspense fallback={<div></div>}><HomeExtra1Comp mode={mode} /></Suspense></InView>
          <InView onChange={setExtra2Comp}><Suspense fallback={<div></div>}><HomeExtra2Comp mode={mode} /></Suspense></InView>
          <InView onChange={setApiComp}><Suspense fallback={<div></div>}><HomeAPIComp mode={mode} /></Suspense></InView>
          <InView onChange={setSpotlightComp}><HomeMediumComp mode={mode} /></InView>
          <InView onChange={setContactUsComp}><Suspense fallback={<div></div>}><HomeContactUsComp mode={mode} /></Suspense></InView>
        </div>


    </>
  );
}

export default HomePage;