import React, { lazy, Suspense } from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";
// import Typewriter from "typewriter-effect";
import { useInView } from 'react-intersection-observer';
import HeaderDyslexiaEffectComp from "../utils/HeaderDyslexiaEffectComp";

const MediumComp = (lazy(() => (import('../comp/MediumOneComp'))));
const NewsLockedComp = (lazy(() => (import('../comp/MediumOneLockedComp'))));

const HOMEPAGE2023HEADER_QUERY = gql`
  query HomePage {
    home2023 {
      data {
        attributes {
          Header {
            __typename,
            ... on ComponentBlockHomeHeader2023 {
              Title1,
              Title2,
              Title3,
              SubTitle,
              Btn {
                Text,
                URL,
                Disable,
                External,
                Icon
              },
              Image {
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                Text
              },
              LockNews,
              LockedNewsTitle,
              LockedNewsURL
            }
          }
        }
      }
    }
  }
`;

function FadeIn(props) {
  const randomDelayArray= [
    'delay-75',
    'delay-100',
    'delay-150',
    'delay-200',
    'delay-300'
  ];

  const { ref, inView } = useInView({
    triggerOnce: true
  });

  return (
    <div ref={ref} className={`fade-in opacity-0 transition-opacity ${randomDelayArray[Math.floor(Math.random() * randomDelayArray.length)]} ${inView ? 'is-visible' : ''}`}>
      {inView ?
        props.children
        : null}
    </div>
  );
}

function HomePageHeaderComp(props) {
  const mode = props.mode ? props.mode : "light";
  const newslocked = false;

  const { ref, inView } = useInView({
    triggerOnce: true
  });

  return (
    <>
      <div className="flex dark:bg-pgray-900 bg-pgray-100 relative min-h-screen" id="header">
        <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
          <div className="z-20 mt-24 relative">
            <Query query={HOMEPAGE2023HEADER_QUERY} id={null}>
              {({ data: { home2023 } }) => {
                return (
                  <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                    <div className="mr-5 sm:mx-10 w-5 mt-52 relative">
                      <div className="h-full flex flex-col gap-4 relative">
                        <span class="relative flex h-5 w-5">
                          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-pgray-300 dark:bg-pgray-500 opacity-75"></span>
                          <span class="relative flex rounded-full h-5 w-5 bg-pgray-300 dark:bg-pgray-500">
                            <span class="m-auto rounded-full h-3 w-3 bg-pgray-400 dark:bg-pgray-400"></span>
                          </span>
                        </span>
                        <div ref={ref} className={`pt-20 bg-pgray-300 dark:bg-pgray-500 w-1 h-full mx-auto rounded-t-xl origin-top transition scale-y-0 duration-700 ease-in-out delay-300 ${inView ? 'scale-y-100' : ''}`}></div>
                      </div>
                    </div>

                    <div className="grow lg:text-left">
                      <div className="flex mb-8">
                        { home2023.data.attributes.Header.LockNews ? <NewsLockedComp title={home2023.data.attributes.Header.LockedNewsTitle} url={home2023.data.attributes.Header.LockedNewsURL}/> : <MediumComp /> }
                      </div>
                      <h1 className="relative tracking-tight font-extrabold font-display dark:text-pwhite text-pgray-900 text-6xl sm:text-8xl ">
                        <span className="">{home2023.data.attributes.Header.Title1}</span>
                        <span className="block flex sm:gap-4 flex-col lg:flex-row">
                          <span className="text-transparent bg-clip-text bg-gradient-to-br from-pblue-500 to-pblue-600 h-15 sm:h-full flex">
                            {home2023.data.attributes.Header.Title2 && <HeaderDyslexiaEffectComp strings={home2023.data.attributes.Header.Title2}/>}
                          </span>
                          <span className="">{home2023.data.attributes.Header.Title3}</span>
                        </span>
                      </h1>
                      <p className="mt-3 lg:justify-start leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-900 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0 " dangerouslySetInnerHTML={{ __html: home2023.data.attributes.Header.SubTitle }}></p>
                      <div className="mt-5 sm:mt-8 flex lg:justify-start gap-4 sm:gap-8">
                        <div className="rounded-xl">
                          <a href={home2023.data.attributes.Header.Btn[0].URL} className="transition-colors ease-in-out w-full flex items-center justify-center px-8 py-3 text-base font-medium font-sans rounded-xl text-pgray-700 hover:text-pwhite dark:text-pwhite bg-transparent hover:bg-pgray-700 md:py-3 md:text-lg md:px-10 border-pgray-700 border-2 dark:border-pwhite">
                            <div dangerouslySetInnerHTML={{ __html: home2023.data.attributes.Header.Btn[0].Icon }}></div>
                            {home2023.data.attributes.Header.Btn[0].Text}
                          </a>
                        </div>
                        <div className="rounded-xl">
                          <a href={home2023.data.attributes.Header.Btn[1].URL} className="transition-colors ease-in-out w-full flex items-center justify-center px-8 py-3 text-base font-medium font-sans rounded-xl text-pwhite hover:text-pwhite dark:text-pwhite dark:bg-pblue-500 bg-pgray-700 hover:bg-pgray-800 hover:dark:bg-pblue-600 md:py-3 md:text-lg md:px-10 border-pgray-700 border-2 dark:border-pblue-500 hover:dark:border-pblue-600 ">
                            <div dangerouslySetInnerHTML={{ __html: home2023.data.attributes.Header.Btn[1].Icon }}></div>
                            {home2023.data.attributes.Header.Btn[1].Text}
                          </a>
                        </div>
                      </div>
                      <div className="mt-8 md:h-2/4 relative">
                        <div className='absolute bottom-0 left-0 w-full lg:w-full bg-gradient-to-t from-pgray-100 dark:from-pgray-900 h-2/4 z-[11]'></div>
                        <div style={{ backgroundImage: mode !== "dark" ? `url(${process.env.REACT_APP_BACKEND_URL + home2023.data.attributes.Header.Image.LightImage.data.attributes.url})` : `url(${process.env.REACT_APP_BACKEND_URL + home2023.data.attributes.Header.Image.DarkImage.data.attributes.url})` }} className="w-full sm:w-3/4 rounded-2xl bg-cover border border-pgray-200 dark:border-pgray-600 h-[240px] md:h-[450px]" />
                      </div>
                    </div>
                  </main>
                );
              }}
            </Query>
          </div>
        </div>

        <div className="relative z-[11]">

          <FadeIn>
            <div className="hidden absolute right-64 top-[20rem] w-20 h-20 bg-gradient-to-tr from-pgray-100 to-pgray-50 dark:from-pgray-600 dark:to-pgray-500 z-[11] rounded-lg shadow-2xl md:flex justify-center items-center">
              <img src="https://cms.parsiq.net/uploads/polygon_a518b81a32.png" className="w-14 h-14" />
            </div>
          </FadeIn>
          <FadeIn>
          <div className="hidden absolute right-20 top-[10rem] w-24 h-24 bg-gradient-to-tr from-pgray-100 to-pgray-50 dark:from-pgray-600 dark:to-pgray-500 z-[11] rounded-lg shadow-2xl md:flex justify-center items-center">
            <img src="https://cms.parsiq.net/uploads/binance_893b9577b1.png" className="w-[4.25rem] h-[4.25rem]" />
          </div>
          </FadeIn>
          <FadeIn>
          <div className="absolute right-40 top-[32rem] w-24 h-24 bg-gradient-to-tr from-pgray-100 to-pgray-50 dark:from-pgray-600 dark:to-pgray-500 z-[11] rounded-lg shadow-2xl flex justify-center items-center">
            <img src="https://cms.parsiq.net/uploads/ethereum_fb64e5a4c0.png" className="w-[4.25rem] h-[4.25rem]" />
          </div>
          </FadeIn>
          <FadeIn>
          <div className="absolute -right-5 top-[40rem] w-24 h-24 bg-gradient-to-tr from-pgray-100 to-pgray-50 dark:from-pgray-600 dark:to-pgray-500 z-[11] rounded-lg shadow-2xl flex justify-center items-center">
            <img src="https://cms.parsiq.net/uploads/arbitrum_47e01c55df.png" className="w-[4.25rem] h-[4.25rem]" />
          </div>
          </FadeIn>
          <FadeIn>
          <div className="absolute -right-10 top-[23rem] w-24 h-24 bg-gradient-to-tr from-pgray-100 to-pgray-50 dark:from-pgray-600 dark:to-pgray-500 z-[11] rounded-lg shadow-2xl flex justify-center items-center">
            <img src="https://cms.parsiq.net/uploads/avalanche_8e37f7bb62.png" className="w-[4.25rem] h-[4.25rem]" />
          </div>
          </FadeIn>
          <FadeIn>
          <div className="absolute right-64 top-[44rem] w-20 h-20 bg-gradient-to-tr from-pgray-100 to-pgray-50 dark:from-pgray-600 dark:to-pgray-500 z-[11] rounded-lg shadow-2xl flex justify-center items-center">
            <img src="https://cms.parsiq.net/uploads/Symbol_Metis_Dao_7f574a08cc.png" className="w-14 h-14" />
          </div>
          </FadeIn>
        </div>


        <div className='absolute -top-[400px] left-0 w-[50rem] h-[50rem] blur-3xl bg-gradient-to-br from-pblue-500 dark:from-pgreen-500 to-pblue-500/0 z-10 rounded-full'></div>
        <div className='absolute bottom-0 left-0 w-full lg:w-full bg-gradient-to-t from-pgray-100 dark:from-pgray-900 h-1/4 z-[11]'></div>
        <div className='absolute bottom-0 md:inset-y-0 left-0 w-full lg:w-full backdrop-blur-md bg-pgray-100 dark:bg-pgray-800 dark:bg-opacity-50 bg-opacity-30 h-full md:h-full z-10'></div>
      </div>
    </>

  );
}

export default HomePageHeaderComp;