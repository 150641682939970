import React, { Component, useState } from "react";
import axios from "axios";
import gql from "graphql-tag";
import Query from "../utils/Query";

const HOMEPAGENEWSLETTER_QUERY = gql`
  query HomePage {
    homepage {
      data {
        attributes {
          Newsletter {
            Title,
            Title2,
            Desc
          }
        }
      } 
    }
  }
`;

class NewsletterSignupComp extends Component {
  render() {
    return (
      <>
        <NewsletterForm />
      </>
    )

    function NewsletterForm(props) {
      const [email, setEmail] = useState('');

      const handleSubmit = async (e) => {
        // e.preventDefault();

        if (!email) {
          alert('Please enter your email.');
        } else {
          const portalId = '8941517';
          const formGuid = '046e9ca6-59ea-4a6f-9a92-9f23d7184f12';
          const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          };
          const response = await axios.post(
            `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
            {
              portalId,
              formGuid,
              fields: [
                {
                  name: 'email',
                  value: email,
                },
              ],
            },
            config
          );
          console.log(response);
          // console.log('test')
          return response;
        }
      };

      return (
        // <Query query={HOMEPAGENEWSLETTER_QUERY} id={null}>
        //   {({ data: { homepage } }) => {
        //     return (
              <form className="">
                <div className="text-pgray-500 font-medium mb-2">NEWSLETTER</div>
                <div className="flex flex-col sm:flex-row w-full space-y-3 sm:space-x-3 sm:space-y-0">
                  <div className="w-full relative rounded-md shadow-sm">
                    <input
                      type='email'
                      className="px-3 py-2 border-1 focus:ring-2 focus:ring-pred-600 block w-full text-base rounded-md placeholder-pgray-500 text-pgray-700 dark:text-pwhite bg-pgray-100 dark:bg-pgray-800"
                      placeholder="john.smith@company.com"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <button className="whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border-transparent rounded-md text-base text-pwhite font-medium bg-pblue-500 hover:bg-pblue-700 hover:text-pwhite hover:no-underline" onClick={() => handleSubmit()} >
                    Subscribe
                  </button>
                </div>
              </form>
                     
        //     );
        //   }}
        // </Query>


      );
    }

  }
}

export default NewsletterSignupComp;
