import React, { useState, useRef } from "react";
import clsx from 'clsx';

function Accordion(props) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
    }

    return (
        <>
            {!props.disabled &&
                <div className="flex flex-col">
                    <button className={`flex items-center justify-between dark:bg-pgray-800 bg-pgray-50 p-4 text-left rounded-2xl hover:bg-pgray-100 ${setActive}`} onClick={toggleAccordion}>
                        <p className="font-medium dark:text-pwhite text-pgray-800">{props.title}</p>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className={clsx("dark:text-pwhite text-pgray-700 h-5 w-5 opacity-60 flex-none", { "rotate-180": setActive })} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" className={clsx("dark:text-pwhite text-pgray-700 h-5 w-5 opacity-60 flex-none", { "rotate-45": setActive })} viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                        </svg>
                    </button>
                    <div className="overflow-hidden accordion" ref={content} style={{ maxHeight: `${setHeight}` }}>
                        <div className="whitespace-pre-wrap dark:text-pwhite text-pgray-800 dark:bg-pgray-900 bg-pwhite">
                            {props.children}
                        </div>
                        {/* {console.log(props.content)} */}
                    </div>
                </div>
            }
        </>

    );
}

export default Accordion;