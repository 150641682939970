import React from "react";
import { Helmet } from 'react-helmet-async';

function MetaComp(props) {

  return (
    <>
      <Helmet>
        {/* {console.log(props)} */}
        <title>{props.data.Title}</title>
        <meta name="title" content={props.data.Title}></meta>
        <meta property="og:title" content={props.data.Title}></meta>
        <meta name="description" content={props.data.Desc}></meta>
        <meta property="og:description" content={props.data.Desc}></meta>
        <meta property="og:image" content={process.env.REACT_APP_BACKEND_URL + props.data.Img.data.attributes.url}></meta>
      </Helmet>
    </>

  );
}

export default MetaComp;