import React, { Component } from "react";
import axios from "axios";

class MediumLineComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: []
        };
    }

    componentDidMount() {
        axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@parsiq").then(posts => {
            // console.log(posts.data);
            const dataarr = [];
            posts.data.items.map((post, i) => (
                i < 6 && dataarr.push([post.title, post.guid, post.pubDate, post.thumbnail])
            ))
            this.setState({
                posts: dataarr
            });
        });
    }

    // formatDate(e) {
    //     const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    //     const current_datetime = new Date(e)
    //     const formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear()
    //     return formatted_date;
    // }


    render() {
        return (
            <div className="dark:bg-pgray-800 bg-white">
                <div className="relative py-6 max-w-8xl mx-auto">
                    {/* <div className="bg-gradient-to-r dark:from-pgray-800 from-pwhite w-60 h-full absolute z-10"></div> */}
                    <div className="marquee-text-wrap overflow-hidden">
                        <div className="marquee-text-content w-infinity">
                            <div className="scroll-left-animation animation-dur-40s flex flex-row ml-6">
                                {this.state.posts && this.state.posts.map((post, i) => ( 
                                    <div className="mr-6" key={i} >
                                        <a href={post[1]} className="font-base font-medium text-pgray-800 dark:text-pwhite hover:text-pblue-500" dangerouslySetInnerHTML={{ __html: post[0] }} />
                                        <span className="ml-6 text-pblue-500">•</span>
                                    </div>                              
                                ))}
                            </div>
                            <div className="scroll-left-animation animation-dur-40s flex flex-row">
                                {this.state.posts && this.state.posts.map((post, i) => ( 
                                    <div className="mr-6" key={i} >
                                        <a href={post[1]} className="font-base font-medium text-pgray-800 dark:text-pwhite hover:text-pblue-500" dangerouslySetInnerHTML={{ __html: post[0] }} />
                                        <span className="ml-6 text-pblue-500">•</span>
                                    </div>                              
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default MediumLineComp;
