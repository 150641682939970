import { lazy, Suspense } from "react";
import { useParams, Navigate } from "react-router-dom";
import gql from "graphql-tag";
import Query from "../utils/Query";
import MediumComp from './MediumComp';

const SEOPageMetaComp = (lazy(() => (import('../comp/SEOPageMetaComp'))));
const SEOPageHeaderComp = (lazy(() => (import('../comp/SEOPageHeaderComp'))));
const SEOPageFeaturesComp = (lazy(() => (import('../comp/SEOPageFeaturesComp'))));
const SEOPageTestimonialComp = (lazy(() => (import('../comp/SEOPageTestimonialComp'))));
const SEOPageMarkdComp = (lazy(() => (import('../comp/SEOPageMarkdComp'))));
const SEOPageCTABlockComp = (lazy(() => (import('../comp/SEOPageCTABlockComp'))));

function SEOBlockchainPage(props) {
  const mode = props.mode ? props.mode : "light";

  let { slug } = useParams();

  const SEOPAGES_QUERY = gql`
    query SEOBlockchainPages($slug: String!) {
      seoBlockchainPagess(filters: { Slug: { eq: $slug } }) {
        data {
          attributes {
            Slug
            Meta {
              Title
              Desc
              Img {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            Header {
              Tag {
                Title
              }
              Title
              SubTitle
              Text2
              Button1 {
                Disable
                External
                Text
                URL 
              }
              Button2 {
                Disable
                External
                Text
                URL 
              }
              BackgroundImage {
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                Text
              }
            }
            Content
            Features {
              Title
              SubTitle
              Feature {
                Title
                Tag
                Text
                Text2
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                ImageText
                ButtonDisable
                ButtonExternal
                ButtonText
                ButtonURL
              }
            }
            Testimonial {
              Title
              SubTitle
              Quote
              Avatar {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            Content2
            CTA {
              BackgroundImage {
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                Text
              }
              Title
              SubTitle
              Link {
                Text
                URL
                Disable
                External
              }
            }
          }
        }
      }
    }
  `;

  return (
    <>
      <Query query={SEOPAGES_QUERY} id={null} slug={slug}>
        {({ data: { seoBlockchainPagess } }) => {
          // if (seoBlockchainPagess.data.length <= 1) {
          //   { console.log(seoBlockchainPagess) }
          // }

          return (
            <>
              {seoBlockchainPagess.data.length == 0 && 
                <Navigate replace to="/404" />
              }
              <Suspense fallback={<div></div>}><SEOPageMetaComp mode={mode} data={seoBlockchainPagess.data[0].attributes.Meta}/></Suspense>
              <Suspense fallback={<div></div>}><SEOPageHeaderComp mode={mode} data={seoBlockchainPagess.data[0].attributes.Header}/></Suspense>
              <Suspense fallback={<div></div>}><SEOPageMarkdComp mode={mode} data={seoBlockchainPagess.data[0].attributes.Content}/></Suspense>
              <Suspense fallback={<div></div>}><SEOPageFeaturesComp mode={mode} data={seoBlockchainPagess.data[0].attributes.Features}/></Suspense>
              <Suspense fallback={<div></div>}><SEOPageTestimonialComp mode={mode} data={seoBlockchainPagess.data[0].attributes.Testimonial}/></Suspense>
              <Suspense fallback={<div></div>}><SEOPageMarkdComp mode={mode} data={seoBlockchainPagess.data[0].attributes.Content2}/></Suspense>
              <Suspense fallback={<div></div>}><SEOPageCTABlockComp mode={mode} data={seoBlockchainPagess.data[0].attributes.CTA}/></Suspense>

              {/* {console.log(seoBlockchainPagess)} */}
              {/* <div className="dark:bg-pgray-800 bg-pgray-50 flex">
                  <div className="w-full pt-8 pb-8 sm:pt-16 sm:pb-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-4 sm:px-6 lg:px-6 border-t dark:border-pgray-600 border-pgray-200"></div>
                  </div>
                </div> */}
              <MediumComp mode={mode} />
            </>
          );
        }}
      </Query>
    </>
  );
}

export default SEOBlockchainPage;