import React from "react";
import { useQuery } from "@apollo/client";
import { useParams, Navigate } from "react-router-dom";

const Query = ({ children, query, slug }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug: slug },
  });

  if (loading) return <p />;
  // if (error) return <p>Error: {JSON.stringify(error)}</p>;
  // if (error) return <p className="dark:text-pwhite">Error: {JSON.stringify(error)}</p>;
  if (error) return <Navigate replace to="/503" />;
  return children({ data });
};

export default Query;
