import React, { Component, useState } from "react";
import axios from "axios";
import gql from "graphql-tag";
import Query from "../utils/Query";
// import HoverShinyEffect from "../utils/HoverShinyEffect";
// import { Helmet } from 'react-helmet-async';

const HOMECONTACTUS_QUERY = gql`
  query Home2023Page {
    home2023 {
      data {
        attributes {
          ContactUs {
            __typename,
            ... on ComponentBlockHome2023ContactUsForm {
              Title {
                Icon
                PreTitle
                Title
                SubTitle
              }
            }
          }
        }
      }
    }
  }
`;

function NewsletterForm(props) {
  const [firstname, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (!message) {
      alert('Please enter your message.');
    } else {
      const portalId = '8941517';
      const formGuid = '2935c0f4-0dc0-4767-bda5-04d0051ded59';
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          portalId,
          formGuid,
          fields: [
            {
              name: 'email',
              value: email,
            },
            {
              name: 'firstname',
              value: firstname,
            },
            {
              name: 'company',
              value: company,
            },
            {
              name: 'message',
              value: message,
            },
          ],
        },
        config
      );
      console.log(response);
      // console.log('test')
      return response;
    }
  };

  return (
    // <Query query={HOMEPAGENEWSLETTER_QUERY} id={null}>
    //   {({ data: { homepage } }) => {
    //     return (
    <form className="grid sm:grid-cols-2 gap-10">
      <div className="flex flex-col gap-8">
        <div className="">
          <div className="text-pgray-500 font-medium mb-2">Email</div>
          <div className="w-full relative rounded-md shadow-sm">
            <input
              type='email'
              className="px-3 py-2 border-1 focus:ring-2 focus:ring-pred-600 block w-full text-base rounded-md placeholder-pgray-500 text-pgray-700 dark:text-pwhite bg-pgray-100 dark:bg-pgray-800"
              placeholder="john.smith@company.com"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="">
          <div className="text-pgray-500 font-medium mb-2">Name</div>
          <div className="w-full relative rounded-md shadow-sm">
            <input
              // type='email'
              className="px-3 py-2 border-1 focus:ring-2 focus:ring-pred-600 block w-full text-base rounded-md placeholder-pgray-500 text-pgray-700 dark:text-pwhite bg-pgray-100 dark:bg-pgray-800"
              // placeholder="john.smith@company.com"
              onChange={(e) => setName(e.target.value)}
              // required
            />
          </div>
        </div>
        <div className="">
          <div className="text-pgray-500 font-medium mb-2">Company</div>
          <div className="w-full relative rounded-md shadow-sm">
            <input
              // type='type'
              className="px-3 py-2 border-1 focus:ring-2 focus:ring-pred-600 block w-full text-base rounded-md placeholder-pgray-500 text-pgray-700 dark:text-pwhite bg-pgray-100 dark:bg-pgray-800"
              // placeholder="john.smith@company.com"
              onChange={(e) => setCompany(e.target.value)}
              // required
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="">
          <div className="text-pgray-500 font-medium mb-2">Message</div>
          <div className="w-full relative rounded-md shadow-sm">
            {/* <input
            type='email'
            className="px-3 py-2 border-1 focus:ring-2 focus:ring-pred-600 block w-full text-base rounded-md placeholder-pgray-500 text-pgray-700 dark:text-pwhite bg-pgray-100 dark:bg-pgray-800"
            placeholder="john.smith@company.com"
            onChange={(e) => setEmail(e.target.value)}
            required
          /> */}
            <textarea
              rows="5"
              // class="mt-1 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
              className="px-3 py-2 border-1 focus:ring-2 focus:ring-pred-600 block w-full text-base rounded-md placeholder-pgray-500 text-pgray-700 dark:text-pwhite bg-pgray-100 dark:bg-pgray-800"
              onChange={(e) => setMessage(e.target.value)}
            >
            </textarea>
          </div>
        </div>
        <div className="flex">
          <button className="ml-auto whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border-transparent rounded-md text-base text-pwhite font-medium bg-pblue-500 hover:bg-pblue-700 hover:text-pwhite hover:no-underline" onClick={() => handleSubmit()} >
            Submit
          </button>
        </div>
      </div>
    </form>

    //     );
    //   }}
    // </Query>


  );
}


function HomePageContactUsComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <div className="flex dark:bg-pgray-900 bg-pwhite relative" id="contactus">
        <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
          <div className="z-20 relative">
            <Query query={HOMECONTACTUS_QUERY} id={null}>
              {({ data: { home2023 } }) => {
                return (
                  <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                    <div className="mr-5 sm:mx-10 w-5 relative">
                      <div className="h-full flex flex-col relative">
                        <div className="bg-pgray-300 dark:bg-pgray-500 w-1 h-20 mx-auto rounded-b-xl"></div>
                        <div className="bg-pgray-300 dark:bg-pgray-500 w-1 h-16 mx-auto rounded-b-xl"></div>
                        <div className="text-pgray-300 relative my-10 flex-none">
                          <div className="h-6 w-6 animate-pulse" dangerouslySetInnerHTML={{ __html: home2023.data.attributes.ContactUs.Title.Icon }}></div>
                          <div className="absolute top-0 left-0 w-6 h-6 rounded-full bg-pgray-400 blur-lg animate-ping duration-300"></div>
                        </div>
                        {/* <div className="pt-20 bg-pgray-500 w-1 mx-auto rounded-t-xl grow"></div> */}
                      </div>
                    </div>

                    <div className="grow sm:text-center lg:text-left mt-20 sm:mr-24">
                      <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600/60 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 mb-2" >{home2023.data.attributes.ContactUs.Title.PreTitle}</p>
                      <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-6xl sm:text-8xl">
                        <span className="">{home2023.data.attributes.ContactUs.Title.Title}</span>
                      </h1>
                      <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 ">{home2023.data.attributes.ContactUs.Title.SubTitle}</p>

                      <div className="dark:bg-pgray-900 border border-pgray-300 dark:border-pgray-600 rounded-xl overflow-hidden relative mt-8 max-w-5xl p-10">
                        <NewsletterForm />
                      </div>
                    </div>


                  </main>
                );
              }}
            </Query>
          </div>
        </div>
      </div>


    </>

  );
}

export default HomePageContactUsComp;