import { lazy, Suspense } from "react";

import MediumComp from '../comp/MediumComp';

const DataLakesPageHeaderComp = (lazy(() => (import('../comp/DataLakesPageHeaderComp'))));
const DataLakesPageFeaturesComp = (lazy(() => (import('../comp/DataLakesPageFeaturesComp'))));
const DataLakesPageHowitWorksComp = (lazy(() => (import('../comp/DataLakesPageHowitWorksComp'))));
const DataLakesPageFAQComp = (lazy(() => (import('../comp/DataLakesPageFAQComp'))));
const DataLakesPageCTABlockComp = (lazy(() => (import('../comp/DataLakesPageCTABlockComp'))));

function DataLakesPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Suspense fallback={<div></div>}><DataLakesPageHeaderComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><DataLakesPageFeaturesComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><DataLakesPageHowitWorksComp mode={mode} /></Suspense>

      <Suspense fallback={<div></div>}><DataLakesPageFAQComp mode={mode} /></Suspense>
      <Suspense fallback={<div></div>}><DataLakesPageCTABlockComp mode={mode} /></Suspense>

      <MediumComp mode={mode} />
    </>
  );
}

export default DataLakesPage;