import { useState, useEffect } from "react";

function ErrorPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <div className="relative flex min-h-screen 2xl:min-h-[1000px] dark:bg-pgray-900 bg-pwhite">
      <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4">
        <div className="relative z-20 pb-8 max-w-full lg:max-w-3xl lg:w-full">
          <h1 className="relative tracking-tight font-extrabold font-display dark:text-pwhite text-pgray-900 text-6xl sm:text-6xl md:text-8xl lg:text-9xl">
            <span className="block xl:inline" >404</span>
          </h1>
          <p className="mt-3 text-3xl sm:text-4xl dark:text-pwhite text-pgray-900 opacity-60 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 sm:leading-relaxed">
            Not Found
          </p>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
